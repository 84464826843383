.main-cont {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow-y: auto;
}

.business-review-card {
    display: flex;
    flex-direction: row;
    border: 1px solid #dddddd;
    border-radius: 16px;
    height: 100%;
    width: 100%;
    padding: 10px 0px;
    position: relative;
    cursor: pointer;
    background: white;
}

.business-review-card-active {
    background: #f3f8fe;
}

.activebar {
    border-left: 4px solid #536784;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.business-review-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #dddddd;
    border-radius: 16px;
    padding: 15px;
}

.c-334155 {
    color: #334155;
}

.c-4C596A {
    color: #4c596a;
}

.c-536784 {
    color: #536784;
}

.c-667085 {
    color: #667085;
}

@media only screen and (max-width: 768px) {
    .main-cont {
        height: 100%;
    }
}
