#performance,
.chartjs-render-monitor {
    /* height: 10.25rem !important; */
    height: max-content !important;
}

.jZZjSQ > .ant-card {
    min-height: auto !important;
}

.ant-card.dPXroS {
    margin-bottom: 0 !important;
}

/* Utilitiy classes */

.ant-layout {
    background-color: var(--c-334155) !important;
}

.spin {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.05);
}

.d-flex {
    display: flex;
}

.flex-grid .flex-grid-child {
    padding: 0 12px;
}

.align-center-v {
    display: flex;
    align-items: center;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.line-height-0 {
    line-height: 0;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.status {
    display: inline !important;
    font-size: 12px;
    font-weight: 500;
    background-color: #868eae10;
    padding: 4.5px 11.85px;
    border-radius: 15px;
}

.status.Success {
    background-color: #20c99710;
    color: #20c997;
}

.status.warning {
    background-color: #fa8b0c10;
    color: #fa8b0c;
}

.status.error {
    background-color: #ff4d4f10;
    color: #ff4d4f;
}

ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

/* spacing classes */

.m-0 {
    margin: 0 !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.pb-30 {
    padding-bottom: 30px;
}

.pl-0 {
    padding-left: 0 !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.pr-0 {
    padding-right: 0 !important;
}

/* default card style */

.ant-card {
    box-shadow: 0 5px 20px #9299b803;
}

.ant-card-body {
    padding: 0px !important;
}

.ant-card-head {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.ant-card-head-title span {
    display: inline-block;
    margin-left: 15px;
    font-size: 11px;
    font-weight: 500;
    color: #868eae;
}

.ant-card-head .ant-card-extra {
    display: flex;
    align-items: center;
}

.ant-card-head .ant-card-extra a {
    color: #868eae;
}

.ant-card-extra .ant-dropdown-trigger {
    line-height: 0;
    order: 1;
    margin-left: 20px;
}

.sDash_unresizable {
    resize: none;
}

/* ant radio group */

.ant-radio-button-wrapper-checked {
    color: #fff !important;
}

/* card nav */

.card-nav ul {
    list-style: none;
    display: flex;
    margin: 0 -8px !important;
}

.card-nav ul li {
    margin: 0 8px !important;
    position: relative;
}

.card-nav ul li a {
    font-weight: 500;
    color: #868eae;
    font-size: 12px;
}

.card-nav ul li.active a {
    color: #ffffff;
    font-weight: 500;
}

.card-nav ul li.active:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: #ffffff;
    left: 0;
    bottom: -19px;
}

/* chart growth upward */

.growth-downward p,
.growth-upward p {
    font-size: 14px;
    color: #868eae;
    margin: 0;
}

.growth-downward h1 sub svg,
.growth-upward h1 sub svg {
    position: relative;
    top: 2px;
    font-size: 14px;
    font-weight: 600;
    left: 5px;
    bottom: 0;
}

/*
.growth-downward h1, .growth-upward h1 {
  font-size: 22px;
  margin: 6px 0 0;
} */

.growth-downward h1 sub {
    color: #ff4d4f;
}

.growth-upward h1 sub {
    color: #20c997;
}

/* Chart */

.chart-label {
    display: flex;
}

.chart-label .chart-label__single {
    align-items: center;
}

.chart-label .chart-label__single:not(:last-child) {
    margin-right: 40px;
}

.chart-label .chart-label__single p {
    margin: 0;
    color: #868eae;
}

/* revenue doughnut */

.revenue-doughnut {
    display: flex;
    justify-content: center;
}

.revenue-doughnut > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.revenue-doughnut .rd-labels {
    width: 100%;
    margin-top: 30px;
}

.revenue-chat-percentage span {
    display: block;
}

.revenue-chat-percentage span:not(:last-child) {
    margin-bottom: 15px;
}

/* progressbars */

.ant-progress {
    display: inline-flex !important;
    align-items: center;
}

.ant-progress > div {
    display: flex;
    flex-direction: column;
}

.ant-progress .ant-progress-outer {
    margin-right: 0 !important;
    padding-right: 0 !important;
}

.revenue-chat-percentage span {
    display: block;
    font-size: 13px;
    color: #868eae;
}

.revenue-chat-percentage span:not(:last-child) {
    margin-bottom: 20px;
}

.ant-progress-status-warning .ant-progress-bg {
    background: #fa8b0c;
}

/* progress bars */

.progress-primary .ant-progress-bg {
    background: #ffffff;
}

.progress-secondary .ant-progress-bg {
    background: #ff69a5;
}

.progress-success .ant-progress-bg {
    background: #20c997;
}

.progress-success .progress-info .ant-progress-bg {
    background: #2c99ff;
}

.progress-warning .ant-progress-bg {
    background: #fa8b0c;
}

.progress-danger .ant-progress-bg {
    background: #ff4d4f;
}

/* color classes */

.color-primary {
    color: #ffffff;
}

.color-secondary {
    color: #ff69a5;
}

.color-info {
    color: #2c99ff;
}

.color-warning {
    color: #fa8b0c;
}

.color-success {
    color: #20c997;
}

.color-danger {
    color: #ff4d4f;
}

.color-dark {
    color: #272b41;
}

.color-error {
    color: #f5222d;
}

.color-gray {
    color: #5a5f7d;
}

/* Button Styles */

.ant-btn.ant-btn-light:focus {
    background: #fff;
    color: #5a5f7d;
}

.button-example .ant-btn {
    margin: 4px;
}

.button-example .ant-btn-group .ant-btn {
    margin: 0;
}

.ant-btn-white {
    color: #5a5f7d !important;
    border-color: #e3e6ef !important;
}

.ant-btn-white:hover {
    color: #ffffff !important;
    background-color: #fff !important;
    border-color: #fff;
}

.ant-btn-white:focus {
    background-color: transparent !important;
}

.ant-btn-link {
    background-color: #fff;
    border-color: #fff;
    color: #ffffff;
}

.ant-btn-link:hover {
    border-color: #fff;
    background: #fff;
}

.ant-btn-light,
.ant-btn-light:hover,
.ant-btn-link:hover,
.ant-btn-dashed {
    color: #5a5f7d !important;
}

.ant-btn-light {
    border-color: #e3e6ef;
    background: #f4f5f7 !important;
}

.ant-btn-light.btn-outlined {
    background: transparent !important;
}

.ant-btn-light.btn-transparent {
    background: #f4f5f715;
}

.ant-btn-dashed {
    border-width: 1px !important;
}

.ant-btn-dashed:hover {
    color: #ffffff;
    border: 1px dashed #ffffff;
}

.ant-btn-primary[disabled] {
    color: #fff;
    background: #ffffff60;
}

.ant-btn-light[disabled] {
    background: #fff;
}

.ant-btn-round.ant-btn-sm {
    height: 38px;
}

.ant-btn-white[disabled] {
    background-color: transparent;
    opacity: 0.6;
}

.ant-btn-white[disabled]:hover {
    color: #5a5f7d !important;
}

.ant-btn-primary[disabled]:hover {
    color: #fff !important;
    background: #ffffff60 !important;
}

.btn-icon {
    padding: 0 13px;
}

.btn-inc,
.btn-dec {
    height: 38px;
    width: 38px;
    font-size: 20px;
    padding: 0 12px !important;
    border-radius: 10px !important;
    border: 0 none;
}

.btn-inc:hover,
.btn-dec:hover {
    background: #ffffff10 !important;
    border: 0 none !important;
}

.btn-inc:hover i,
.btn-inc:hover svg,
.btn-dec:hover i,
.btn-dec:hover svg {
    color: #ffffff;
}

/* input styles */

.ant-form-item-label > label {
    font-weight: 500;
}

.ant-picker-input > input::placeholder {
    color: #adb4d2 !important;
}

/* Calendar Styles */

.ant-picker-calendar-header .ant-select-selector {
    height: 32px !important;
}

.ant-picker-calendar-header .ant-select-selection-search-input {
    height: 30px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 30px !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #fff !important;
    color: #ffffff !important;
}

/* pagination */

.ant-pagination
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 32px !important;
}

.ant-pagination-item-active {
    background-color: #ffffff !important;
}

.ant-pagination-item-active a {
    color: #fff !important;
}

.ant-pagination .ant-pagination-options .ant-select-selection-item {
    font-size: 13px;
    line-height: 30px !important;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
    height: 30px;
    line-height: 30px;
}

.ant-pagination
    .ant-pagination-options
    .ant-pagination-options-quick-jumper
    input {
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-options .ant-select-selector {
    border: 1px solid #f1f2f6 !important;
    background-color: #fff;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
    color: #5a5f7d !important;
    line-height: 2.6;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-link {
    display: block;
}

.ant-pagination-prev,
.ant-pagination-next {
    line-height: 28px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    border: 0 none !important;
}

.ant-pagination .ant-pagination-item a {
    color: #5a5f7d;
}

.ant-pagination
    .ant-select-single
    .ant-select-selector
    .ant-select-selection-item {
    line-height: 30px !important;
    color: #5a5f7d;
}

.ant-pagination
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100% !important;
}

.ant-pagination {
    margin: -4px -12px !important;
}

.ant-pagination-item,
.ant-pagination-options,
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-next {
    margin: 4px !important;
}

.ant-table-pagination {
    margin-top: 30px !important;
}

/* Wizard Modal */
.submission-successModal {
    text-align: center;
}

.submission-successModal .icon-success {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px solid #20c997;
}

.submission-successModal p {
    margin-bottom: 0;
}

.submission-successModal .icon-success svg {
    color: #20c997;
}

.submission-successModal .submission-action {
    margin: 30px -5px -5px -5px;
}

.submission-successModal .submission-action button {
    margin: 5px;
    height: 38px;
}

/* Tree Select */

.ant-tree-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 42px !important;
    line-height: 40px;
}

.ant-tree-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100% !important;
}

.ant-tree-select.ant-select-single
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 40px !important;
}

/* radio style */

.ant-radio-checked .ant-radio-inner {
    border-width: 5px !important;
}

.ant-radio-inner::after {
    content: none !important;
}

/* Statistics */

.ant-statistic .ant-statistic-title {
    color: #9299b8;
}

.ant-statistic .ant-statistic-content span {
    font-size: 20px;
}

/* Steps */

.steps-action.justify-content-center {
    justify-content: center !important;
}

/* rate */

.ant-rate-star.ant-rate-star-zero span svg {
    color: #c6d0dc;
}

.ant-rate-star:not(:last-child) {
    margin-right: 2px !important;
}

.ant-rate-text {
    color: #5a5f7d;
}

/* result */

.ant-result-icon {
    margin-bottom: 20px !important;
}

.ant-result-title {
    font-weight: 500;
    margin-bottom: 10px;
}

.ant-result-extra {
    height: 34px;
    padding: 4px 10.72px;
}

.ant-result-content .ant-typography strong {
    font-weight: 500;
}

.ant-result-content .ant-typography:last-child {
    margin-bottom: 0;
}

/* form select */

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 33px !important;
}

.ant-select-multiple .ant-select-selector {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.sDash_fullwidth-select {
    width: 100%;
}

/* Nasted Comments Styles */

.nested-comment-wrapper .comment-title {
    font-size: 12px;
    padding-bottom: 10px;
    margin-bottom: 22px;
    border-bottom: 1px solid #e3e6ef;
}

/* calendar style */

.events {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 100%;
    overflow-y: scroll;
}

.events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
}

.notes-month {
    text-align: center;
    font-size: 28px;
}

.notes-month section {
    font-size: 28px;
}

/* Breadcrumb demo */

.demo {
    margin: 16px;
}

.demo-nav {
    height: 30px;
    line-height: 30px;
    margin-bottom: 16px;
    background: #f8f8f8;
}

.demo-nav a {
    line-height: 30px;
    padding: 0 8px;
}

.app-list {
    margin-top: 16px;
}

/* Modal */

.ant-modal .ant-modal-content {
    border-radius: 10px;
}

.ant-modal .ant-modal-header {
    border-radius: 10px 10px 0 0;
}

.ant-modal-body p:last-child {
    margin-bottom: 0;
}

.project-modal {
    padding: 5px !important;
}

.project-modal .projects-members {
    margin-top: -12px;
}

.project-modal .ant-form-item-control-input {
    margin-top: 10px;
    min-height: auto;
}

.project-modal .ant-select-selector {
    border-color: #e3e6ef;
}

.project-modal-footer {
    padding: 10px !important;
    text-align: left !important;
}

.sDash_addTask-modal {
    max-width: 390px;
}

.sDash_addTask-modal .ant-modal-header {
    border-bottom: 0 none;
    padding-bottom: 0 !important;
}

.sDash_addTask-modal .ant-modal-header .ant-modal-title {
    font-size: 16px;
    font-weight: 500;
    color: #272b41;
}

.sDash_addTask-modal .sDash_addTask-modal-inner .ant-form-item {
    margin-bottom: 15px;
}

.sDash_addTask-modal .ant-modal-header .ant-modal-close-x svg {
    color: #9299b8;
}

.sDash_addTask-modal .ant-form-item-control-input {
    min-height: 36px;
}

.sDash_addTask-modal input::placeholder,
.sDash_addTask-modal textarea::placeholder {
    color: #9299b8;
}

.sDash_addTask-modal input,
.sDash_addTask-modal textarea {
    padding: 6px 20px;
    border-radius: 5px;
}

.sDash_addTask-modal textarea {
    resize: none;
    min-height: 125px;
}

.sDash_addTask-modal .sDash-modal-actions {
    display: flex;
    justify-content: flex-end;
    margin: -6px;
}

.sDash_addTask-modal .sDash-modal-actions button {
    font-size: 14px;
    margin: 6px;
    height: 38px;
    border-radius: 5px;
}

/* slider */

.ant-slider-handle {
    margin-top: -6px !important;
}

/* tags */

.ant-tag {
    font-weight: 600;
    padding: 0 9.5px;
}

/* tabs */

.ant-tabs-tab span {
    display: flex;
    align-items: center;
}

/* list */

.ant-list-bordered {
    border-color: #e3e6ef !important;
}

.ant-list-item-meta {
    padding: 10px 24px;
    border-bottom: 1px solid #f1f2f6;
}

/* popover */

.ant-popover {
    position: fixed;
    z-index: 99999;
}

.ant-popover-inner {
    padding: 15px;
    box-shadow: 0 5px 20px #9299b820;
    border-radius: 0 0 6px 6px;
}

.ant-popover-inner .ant-popover-title {
    padding: 5px 10px 10px;
}

.ant-popover-inner .ant-popover-inner-content a {
    color: #5a5f7d;
}

/* Drawer */

.ant-drawer {
    z-index: 99999;
}

/* Select Dropdwon */

.ant-select-dropdown {
    padding: 18px 0 !important;
    box-shadow: 0 5px 20px #9299b820 !important;
    border-radius: 0 0 6px 6px !important;
}

.ant-select-item {
    min-height: 20px !important;
    padding: 4px 12px !important;
}

.ant-select-item-group {
    color: #9299b8;
}

.ant-select-item.ant-select-item-option-grouped {
    padding-left: 25px !important;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-active {
    background: #ffffff05;
}

.ant-select-dropdown
    .ant-select-item.ant-select-item-option-selected
    .ant-select-item-option-content {
    /* padding-left: 10px; */
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
    color: #ffffff;
    background: #ffffff06;
}

.ant-select-dropdown
    .ant-select-item.ant-select-item-option-selected
    .ant-select-item-option-content {
    color: #ffffff;
    font-weight: 500;
}

.ant-select-dropdown .ant-select-item .ant-select-item-option-content {
    transition: 0.3s;
    color: #5a5f7d;
}

/* mail props dropdown */

.mail-props {
    padding: 5px 25px;
    border: 0 none;
    background: #fff;
    /* box-shadow: 0 5px 40px rgba(146, 153, 184, 0.25); */
}

.mail-props li {
    display: flex;
    margin-bottom: 12px;
}

.mail-props li span:first-child {
    margin-right: 50px;
    min-width: 40px;
}

.mail-props li span:last-child {
    color: #5a5f7d;
}

.mail-props li:last-child {
    margin-bottom: 0;
}

.mail-props li span {
    color: #9299b8;
}

/* Basic Dropdwon */

.ant-dropdown {
    box-shadow: 0 5px 30px #9299b820 !important;
}

.ant-dropdown.wide-dropdwon {
    min-width: 140px !important;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more {
    min-width: 220px !important;
    box-shadow: 0 17px 20px #9299b820;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more a {
    padding: 10px 24px;
}

.ant-dropdown-menu {
    min-width: 200px;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 0;
}

.ant-dropdown-menu li {
    color: #5a5f7d;
    padding: 5px 25px;
}

.ant-dropdown-menu li:hover {
    background-color: #ffffff05;
}

.atbd-top-dropdwon .atbd-top-dropdwon__title {
    background: #f4f5f7;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    min-height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.atbd-top-dropdwon {
    width: 340px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav {
    height: 360px;
    overflow: hidden;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav.notification-list {
    padding: 0 10px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li {
    width: 100%;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a {
    padding: 13px 10px;
    position: relative;
    width: 100%;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li:last-child {
    margin: 0;
}

.atbd-top-dropdwon a.btn-seeAll {
    position: relative;
    width: calc(100% + 30px);
    left: -15px;
    right: -15px;
    height: calc(100% + 15px);
    bottom: -15px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #ffffff;
    padding: 15px 0;
    border-radius: 0 0 6px 6px;
    background: #fff;
}

.atbd-top-dropdwon a.btn-seeAll:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 -15px 20px #9299b808;
    z-index: 1;
    content: '';
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a.btn-seeAll:hover:after {
    box-shadow: 0 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:hover {
    background: #fff;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:hover:after {
    opacity: 1;
    visibility: visible;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:after {
    position: absolute;
    left: -15px;
    right: -15px;
    top: 0;
    width: calc(100% + 30px);
    height: 100%;
    box-shadow: 0 15px 50px #9299b820;
    z-index: 1;
    content: '';
    opacity: 0;
    visibility: hidden;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon {
    width: 39.2px;
    height: 32px;
    margin-right: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon.bg-primary {
    background: #ffffff15;
    color: #ffffff;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon.bg-secondary {
    background: #ff69a515;
    color: #ff69a5;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon svg {
    width: 18px;
    height: 18px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.atbd-top-dropdwon .notification-text h1 {
    font-size: 14px;
    font-weight: 400;
    color: #5a5f7d;
    margin-bottom: 4px;
}

.atbd-top-dropdwon .notification-text h1 span {
    color: #ffffff;
    font-weight: 500;
    padding-left: 0;
}

.atbd-top-dropdwon .notification-text p {
    font-size: 12px;
    color: #adb4d2;
    margin-bottom: 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content img {
    max-width: 40px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption {
    margin: -4px 15px 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption .ant-badge-count {
    font-size: 8px;
    min-width: 16px;
    width: 16px;
    height: 16px;
    line-height: 6px;
    border-radius: 8px;
}

.atbd-top-dropdwon
    .atbd-top-dropdwon__content
    figcaption
    p.ant-scroll-number-only-unit.current {
    height: 15px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption h1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption h1 span {
    font-size: 12px;
    font-weight: 400;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.add-file-dropdown a {
    font-size: 14px;
    font-weight: 400;
}

.add-file-dropdown a svg {
    width: 14px;
}

.folder-dropdown {
    min-width: 200px !important;
}

/* Tooltip Styles */

.ant-tooltip .ant-tooltip-inner {
    min-height: 40px;
    padding: 6px 20px;
    font-size: 14px;
    color: #5a5f7d;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    align-items: center;
    border: 1px solid #f1f2f6;
    box-shadow: 0 8px 15px #9299b815;
}

.ant-tooltip .ant-tooltip-arrow {
    /* position: relative; */
}

.ant-tooltip .ant-tooltip-arrow:after {
    position: absolute;
    left: 50%;
    top: 12px;
    transform: translateX(-50%);
    width: 13px;
    height: 13px;
    background: #fff;
    content: '';
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
    width: 10px;
    height: 10px;
    background: #fff;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow .ant-tooltip-arrow-content {
    border: 1px solid #f1f2f6;
    box-shadow: 0 0;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
    top: -4.071068px;
}

/* Badge Styles */

.ant-badge.badge-success .ant-badge-count {
    background: #20c997;
}

.badge {
    font-size: 11px;
    font-weight: 500;
    padding: 0 6.5px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.badge.badge-primary {
    background: #ffffff10;
    color: #ffffff;
}

/* Cascade Styles */

.custom-cascade-render .ant-cascader-picker {
    width: 100%;
}

.ant-cascader-picker input::placeholder {
    color: #adb4d2;
}

/* Comment Styles */

.ant-comment-actions li {
    margin-bottom: 0 !important;
}

/* Radio Vertical Button */

.ant-radio-vertical .ant-radio-wrapper {
    display: block;
}

.ant-radio-vertical .ant-radio-wrapper:not(:last-child) {
    margin-bottom: 14px;
}

/* Select Tree */

.ant-select-tree-list .ant-select-tree-node-content-wrapper:hover {
    background-color: #bae7ff !important;
}

.ant-select-tree-list .ant-select-arrow svg {
    font-size: 10px;
}

.ant-tree-select.ant-select-multiple .ant-select-selection-item {
    border: 0 none;
    border-radius: 3px;
    background: #f4f5f7;
    color: #5a5f7d;
    font-weight: 500;
}

/* Ant Switch */

.ant-switch:after {
    width: 14px !important;
    height: 14px !important;
}

.ant-switch-small:after {
    width: 10px !important;
    height: 10px !important;
}

/* Time Picker */

.ant-picker {
    min-width: 250px;
}

/* Input Affix */

.ant-input-affix-wrapper > input.ant-input {
    padding-left: 5px;
}

.ant-input-affix-wrapper .ant-input-prefix svg {
    color: #e3e6ef;
}

.ant-input {
    border-radius: 20px !important;
}

.ant-input:hover {
    border-color: #e3e6ef !important;
}

.ant-input:focus,
.ant-input-focused {
    border-color: #e3e6ef !important;
    box-shadow: none !important;
    border-right-width: 0px;
    outline: 0;
}

.ant-form-item:not(:last-child),
.ant-form-item-row:not(:last-child) {
    margin-bottom: 0px !important;
}

/* Space Item */

.ant-space-item .ant-btn span {
    font-size: 14px;
}

/* Pop confirm */

.pop-confirm .ant-btn {
    max-width: 90px;
    padding: 0px 35.5px;
    height: 44px;
}

.pop-confirm-top {
    margin-bottom: 10px;
}

.pop-confirm-bottom {
    margin-top: 10px;
}

.pop-confirm-top .ant-btn:not(:last-child),
.pop-confirm-bottom .ant-btn:not(:last-child) {
    margin-right: 10px;
}

.pop-confirm-left .ant-btn:not(:last-child),
.pop-confirm-right .ant-btn:not(:last-child) {
    margin-bottom: 10px;
}

/* Ant Upload */

.ant-upload .ant-btn {
    font-size: 14px;
    border-radius: 5px;
}

.ant-upload.ant-upload-select-picture-card {
    border-color: #e3e6ef !important;
    border-radius: 5px !important;
    background-color: #f8f9fb !important;
}

.ant-upload.ant-upload-select-picture-card .anticon {
    margin-bottom: 8px;
}

.ant-upload .anticon svg {
    color: #9299b8;
}

.ant-upload-list {
    margin-top: 10px !important;
}

.sDash_upload-basic .ant-upload.ant-upload-select {
    width: 100%;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
}

.sDash_upload-basic .ant-upload.ant-upload-select .ant-upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sDash_upload-basic .sDash_upload-text {
    font-size: 14px;
    color: #9299b8;
    padding-left: 15px;
}

html[dir='rtl'] .sDash_upload-basic .sDash_upload-text {
    padding-left: 0px;
    padding-right: 15px;
}

.sDash_upload-basic .sDash_upload-browse {
    font-size: 14px;
    color: #9299b8;
    display: inline-block;
    padding: 14px 23px;
    border-left: 1px solid #e3e6ef;
}

html[dir='rtl'] .sDash_upload-basic .sDash_upload-browse {
    border-left: 0 none;
    border-right: 1px solid #e3e6ef;
}

.ant-card.sDash_upload-form .ant-card-body {
    padding-bottom: 15px !important;
}

/* Ant Picker */

.ant-picker {
    border-color: #e3e6ef !important;
}

/* Ant Dropdown */

.ant-dropdown {
    box-shadow: 0 5px 20px #9299b820;
    background: #fff;
    border-radius: 6px;
    padding: 15px 0 !important;
    border: 0 none;
}

.ant-dropdown a {
    display: flex;
    align-items: center;
    padding: 8px 24px;
    font-weight: 400;
    color: #5a5f7d;
}

.ant-dropdown a i,
.ant-dropdown a svg,
.ant-dropdown a img {
    margin-right: 8px;
}

.ant-dropdown div {
    box-shadow: 0 0;
    border-radius: 5px;
}

/* Picker Under Input */

.ant-form-item-control-input .ant-picker {
    padding: 0 12px 0 0;
}

/* Leaflet COntainer */

.leaflet-container {
    z-index: 0;
}

/* Table Bordered*/

.table-bordered .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f8f9fb !important;
}

.table-bordered .ant-table-thead > tr > th {
    background: #fff;
    border-top: 1px solid #f1f2f6;
}

.table-bordered .ant-table-tbody > tr > td {
    border-color: #f1f2f6 !important;
}

.table-bordered .ant-table-thead tr th,
.table-bordered .ant-table-tbody tr td {
    padding: 16px 25px;
}

.table-bordered .ant-table-thead tr th:last-child,
.table-bordered .ant-table-tbody tr td:last-child {
    text-align: right;
}

/* Full Width Table */

.full-width-table .ant-card-body {
    padding: 0 !important;
}

.full-width-table .ant-table {
    border-radius: 10px !important;
}

.full-width-table .top-seller-table {
    min-height: 406px;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell {
    white-space: normal;
    padding: 16px 15px;
}

.full-width-table
    .top-seller-table
    .ant-table-content
    .ant-table-cell:first-child {
    padding-left: 25px;
}

.full-width-table
    .top-seller-table
    .ant-table-content
    .ant-table-cell:last-child {
    padding-right: 25px;
}

.full-width-table .top-seller-table th {
    text-align: right;
    color: #272b41;
    font-weight: 500;
}

.full-width-table .top-seller-table td {
    color: #5a5f7d;
}

.full-width-table .top-seller-table .ant-table-tbody > tr > td {
    text-align: right;
}

.full-width-table .top-seller-table th:first-child,
.full-width-table .top-seller-table td:first-child {
    text-align: left !important;
}

/* Table Responsive */

.table-responsive .ant-table-content {
    display: block;
    width: 100%;
    overflow-x: auto;
}

.table-responsive .ant-table-content .ant-table-cell {
    white-space: nowrap;
}

/* Rich TextEditor  */

.RichTextEditor__root___2QXK- {
    border: 0 none !important;
}

.RichTextEditor__root___2QXK- .EditorToolbar__root___3_Aqz {
    margin: 0;
    border-color: #f1f2f6;
}

.RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button {
    padding: 0;
    border: 0 none;
    background: #fff;
    margin-right: 8px;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx .Dropdown__value___34Py9 {
    border: 0 none;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx select {
    border-right-width: 0px;
}

.RichTextEditor__editor___1QqIU .DraftEditor-editorContainer {
    border: 0 none;
}

/* ChatBox Dropdwon */

.atbd-chatbox__messageControl {
    min-width: 210px;
}

.atbd-chatbox__messageControl ul li a {
    padding: 4px 24px;
}

.atbd-chatbox__emoji {
    margin: -4.48px 0;
    padding: 0 10px;
}

.atbd-chatbox__emoji ul {
    display: flex;
    align-items: center;
}

.atbd-chatbox__emoji ul li {
    display: inline-block;
}

.atbd-chatbox__emoji ul li a {
    display: block;
    font-size: 20px;
    padding: 4px 7px;
    background: #fff;
}

.atbd-chatbox__emoji ul li a:hover {
    background-color: transparent;
}

.atbd-chatbox__emoji ul li a svg {
    margin: 0;
}

.rdrMonths {
    flex-wrap: wrap;
}

.ant-space {
    flex-wrap: wrap;
}

.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
    display: flex;
    align-items: center;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-item {
    display: inline-flex;
}

.ant-menu-submenu-popup {
    z-index: 105;
}

.ant-menu-submenu-popup .ant-menu-sub {
    padding: 8px 0;
}

.ant-menu-submenu-popup
    .ant-menu-submenu.ant-menu-submenu-inline
    .ant-menu-submenu-title,
.ant-menu-submenu-popup .ant-menu-item {
    display: block;
}

.ant-menu-submenu-popup .ant-menu-item {
    margin-bottom: 0 !important;
}

.ant-menu-sub.ant-menu-vertical {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.ant-menu-sub.ant-menu-vertical .ant-menu-item a {
    color: #5a5f7d;
}

/* page heading */

.ant-page-header {
    padding: 26px 30px 26px 30px !important;
}

.ant-page-header.header-boxed {
    padding: 26px 180px 26px 180px !important;
}

.ant-page-header-heading {
    align-items: center;
}

.ant-page-header-heading-title {
    text-transform: capitalize;
}

/* Antd drag & drop */

.row-dragging {
    /* background: #fafafa; */
    /* border: 1px solid #ccc; */
    box-shadow: 0 15px 50px #9299b820;
    display: flex;
}

.row-dragging tr {
    box-shadow: 0 15px 50px #9299b820;
}

.row-dragging td {
    padding: 16px;
    color: #000;
    position: relative;
    z-index: 9999;
    opacity: 0.5;
    vertical-align: middle;
}

.row-dragging td .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #20c997;
    border-color: #20c997;
}

.row-dragging td .todos-action,
.row-dragging td .table-actions {
    display: none;
}

.row-dragging td .user-info figcaption {
    display: none;
}

.row-dragging td .feather-move,
.row-dragging td .drag_email,
.row-dragging td .drag_company,
.row-dragging td .drag_designation,
.row-dragging td .drag_join-date,
.row-dragging td .active {
    display: inline-block;
    margin-top: 10px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

/* File Export Import Modal */
.sDash_export-wrap .ant-modal {
    width: 390px !important;
}

.sDash_export-wrap .ant-modal .ant-modal-header {
    border-bottom: 0 none;
    padding: 18px 30px 0 30px;
}

.sDash_export-wrap .ant-modal .ant-modal-body {
    padding: 25px 30px 30px 30px;
}

.sDash_export-wrap .ant-modal .sDash-button-grp {
    text-align: right;
}

.sDash_export-wrap .ant-modal .sDash-button-grp {
    margin: 20px -8px -8px -8px;
}

.sDash_export-wrap .ant-modal .sDash-button-grp button {
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    height: 40px;
    padding: 0 16px;
    margin: 8px;
}

.sDash_export-wrap .ant-modal .sDash-button-grp button.ant-btn-white {
    color: #9299b8 !important;
}

.sDash_export-wrap .ant-form-item-control-input {
    min-height: 36px;
}

.sDash_export-wrap .ant-form-item-control-input input {
    font-size: 14px;
    font-weight: 400;
    padding: 6px 20px;
    border-radius: 5px;
    color: #9299b8;
}

.sDash_export-wrap .ant-select-single {
    width: 100% !important;
}

.sDash_export-wrap .ant-form .ant-form-item {
    margin-bottom: 15px;
}

.sDash_export-wrap .ant-select-single .ant-select-selector {
    padding: 0 20px;
    border-color: #e3e6ef !important;
}

.sDash_create-file .sDash-button-grp {
    text-align: right;
}

.sDash_create-file .ant-modal {
    width: 390px !important;
}

.sDash_create-file .ant-modal .ant-form-item {
    margin-bottom: 20px;
}

.sDash_create-file .ant-modal-header {
    border-bottom: 0 none;
    padding-bottom: 6px;
}

.sDash_create-file .sDash-button-grp button {
    height: 40px;
    border-radius: 5px;
    margin: 5px;
}

.sDash_create-file .ant-form-item-control-input {
    border-radius: 5px;
}

.sDash_create-file .ant-form-item-control-input input {
    border: 1px solid #e3e6ef;
}

/* Task Modal */
.sDash_task-details .ant-modal-content .ant-modal-close {
    top: 10px;
}

.sDash_task-details .ant-modal-header {
    border-bottom: 0 none;
    padding: 30px 30px 0;
}

.sDash_task-details .ant-modal {
    width: 600px !important;
}

.sDash_task-details .ant-modal-header .ant-modal-title h4 {
    font-size: 20px;
    font-weight: 500;
    color: #272b41;
    margin-bottom: 4px;
}

.sDash_task-details .ant-modal-header .ant-modal-title .sub-text {
    font-size: 14px;
    font-weight: 400;
    color: #868eae;
}

.sDash_task-details .sDash_task-details-modal .sDash_task-details__label {
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.sDash_task-details
    .sDash_task-details-modal
    .sDash_task-details-modal__description
    textarea {
    padding: 10px 20px;
    min-height: 88px;
    width: 100%;
    border: 0 none;
    border-radius: 4px;
    background-color: #f4f5f7;
    resize: none;
}

.sDash_task-details
    .sDash_task-details-modal
    .sDash_task-details-modal__description
    textarea:focus {
    outline: none;
}

.sDash_task-details
    .sDash_task-details-modal
    .sDash_task-details-modal__description
    textarea::placeholder {
    color: #5a5f7d;
    font-size: 15px;
}

.sDash_task-details .ant-modal-body {
    padding: 14px 30px 30px 30px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block {
    margin-top: 26px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-wrap {
    position: relative;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form {
    position: absolute;
    width: 240px;
    padding: 18px;
    left: 0;
    top: 50px;
    box-shadow: 0 15px 30px #9299bb30;
    background-color: #fff;
    border: 1px solid #e3e6ef;
    border-radius: 6px;
    z-index: 222;
}

html[dir='rtl']
    .sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form {
    left: auto;
    right: 0;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form
    .add-checklist {
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    width: 100%;
    height: 38px;
    border: 1px solid #e3e6ef;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form
    .addChecklist-form-action {
    margin-top: 15px;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form
    .addChecklist-form-action
    a {
    position: relative;
    top: 3px;
    display: inline-flex;
    align-items: center;
    line-height: 1;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form
    .addChecklist-form-action
    a
    svg {
    color: #9299bb;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form
    .addChecklist-form-action
    .btn-add {
    margin-right: 15px;
}

html[dir='rtl']
    .sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form
    .addChecklist-form-action
    .btn-add {
    margin-left: 15px;
    margin-right: 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block button {
    height: 38px;
    padding: 0px 18.37px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row {
    margin-top: 26px;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist-item:not(:last-child) {
    margin-bottom: 30px;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist-item__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist-item__top
    button {
    padding: 0px 16.32px;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist-item__title {
    font-size: 16px;
    font-weight: 500;
    color: #272b41;
    margin-bottom: 0;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist__progress
    .ant-progress-inner
    .ant-progress-bg {
    height: 5px !important;
    background-color: #20c997;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist__progress
    .ant-progress
    .ant-progress-text {
    font-size: 12px;
    font-weight: 500;
    order: -1;
    margin: 0 10px 0 0;
}

html[dir='rtl']
    .sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist__progress
    .ant-progress
    .ant-progress-text {
    margin: 0 0 0 10px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-tasks {
    margin: 5px 0 5px 0;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-tasks
    .ant-checkbox-wrapper
    .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border-color: #c6d0dc;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-tasks
    .ant-checkbox-wrapper
    .ant-checkbox-checked
    .ant-checkbox-inner {
    background-color: #20c997;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-tasks
    .ant-checkbox-wrapper
    .ant-checkbox-checked:after {
    border-color: #20c997;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-item
    .sDash_checklist-tasks-wrap
    button {
    padding: 0px 35px;
}

.sDash_task-details .ant-modal-body {
    max-height: 800px;
    overflow-y: auto;
}

@media (max-width: 767px) {
    .ant-page-header {
        padding: 26px 15px 26px 15px !important;
    }
}

.page-header-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: -6px -4px;
}

.page-header-actions button {
    font-size: 12px;
    font-weight: 500;
    height: 34px;
    padding: 0 12.32px;
    box-shadow: 0 3px 5px #9299b805;
    margin: 6px 4px;
}

.page-header-actions button.ant-btn-white:focus {
    background-color: #fff !important;
}

.page-header-actions button + button {
    margin-left: 8px;
}

.page-header-actions button.ant-btn-white svg {
    width: 12px;
    height: 12px;
    margin-right: 2px;
    color: #ffffff;
}

/* Layout Css */

.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
    background: #272b41 !important;
}

.ant-menu-dark .ant-menu-item a {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark .ant-menu-item a:hover {
    color: #ffffff;
}

.ant-menu-dark .ant-menu-submenu span {
    color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.05);
}

.ant-menu-inline-collapsed-tooltip a {
    color: #000 !important;
}

.ant-menu.ant-menu-dark
    .ant-menu-submenu-title
    .ant-menu-submenu-arrow::before {
    background: transparent;
}

/* Chart Label */

.chart-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    color: #5a5f7d;
}

.chart-label .label-dot {
    margin-right: 8px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

.chart-label .label-dot.dot-success {
    background: #20c997;
}

.chart-label .label-dot.dot-info {
    background: #ffffff;
}

.chart-label .label-dot.dot-warning {
    background: #fa8b0c;
}

/* NOtification CSS */

.ant-notification {
    z-index: 99999 !important;
}

.rdrInputRange {
    padding-left: 10px !important;
}

/* Overlay Dark */
.overlay-dark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #10122130;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
}

.overlay-dark.show {
    z-index: 999;
    opacity: 1;
    visibility: visible;
}

.ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
}

/* Responsive CSS */

@media only screen and (max-width: 1599px) {
    .ant-page-header.header-boxed {
        padding: 26px 130px 26px 130px !important;
    }
}

@media only screen and (max-width: 1500px) {
    .full-width-table .revenue-table {
        min-height: 100%;
    }

    .pop-confirm .ant-btn {
        padding: 0 20px;
        max-width: 60px;
    }

    .pop-confirm.pop-confirm-right {
        margin-left: 300px !important;
    }

    .pop-confirm.pop-confirm-bottom,
    .pop-confirm.pop-confirm-top {
        margin-left: 80px !important;
    }
}

@media only screen and (max-width: 1399px) {
    .ant-page-header.header-boxed {
        padding: 26px 50px 26px 50px !important;
    }
}

@media only screen and (max-width: 1199px) {
    .ant-page-header {
        padding: 0 15px;
    }

    /* #performance,
    .chartjs-render-monitor {
        height: 8rem !important;
    } */
}

@media only screen and (max-width: 991px) {
    .ant-page-header.header-boxed {
        padding: 26px 30px 26px 30px !important;
    }

    .rdrMonths .rdrMonth {
        width: 100%;
        margin-bottom: 30px;
    }

    .rdrDateRangePickerWrapper.PreviewArea {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rdrCalendarWrapper.rdrDateRangeWrapper {
        margin-top: 20px;
    }

    .ant-menu-inline-collapsed-tooltip {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .ant-page-header-heading-left {
        max-width: 320px;
    }
}

@media only screen and (max-width: 767px) {
    .ant-page-header {
        padding: 26px 15px 26px 15px !important;
    }

    .ant-page-header-heading {
        flex-flow: column;
        align-items: center;
        justify-content: center !important;
    }

    .ant-page-header-heading-left {
        max-width: 100%;
    }

    .ant-page-header-heading .ant-page-header-heading-title {
        margin-right: 0;
        white-space: normal;
        text-align: center;
    }

    .ant-page-header-heading-extra {
        white-space: normal !important;
        margin: 12px 0 4px !important;
    }

    .ant-page-header-heading-extra .page-header-actions {
        white-space: normal !important;
        text-align: center;
    }

    .ant-card-head {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .ant-pagination .ant-pagination-item,
    .ant-pagination .ant-pagination-item.ant-pagination-prev,
    .ant-pagination .ant-pagination-item.ant-pagination-next,
    .ant-pagination .ant-pagination-jump-prev {
        margin-right: 5px;
    }

    .ant-pagination li.ant-pagination-item,
    .ant-pagination li.ant-pagination-prev,
    .ant-pagination li.ant-pagination-next {
        height: 25px;
        min-width: 25px;
        line-height: 22px;
    }

    .ant-pagination li.ant-pagination-prev .anticon,
    .ant-pagination li.ant-pagination-next .anticon {
        vertical-align: 0.15em;
    }

    .ant-table-pagination {
        float: none !important;
        text-align: center;
    }

    .ant-table-pagination li.ant-pagination-total-text {
        display: block;
        margin-bottom: 8px;
    }

    .ant-table-pagination li {
        margin-right: 8px !important;
    }

    .ant-pagination
        .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
        height: 25px !important;
    }

    .ant-pagination
        .ant-select-single
        .ant-select-selector
        .ant-select-selection-item {
        line-height: 23px !important;
    }

    .rdrDefinedRangesWrapper {
        border-right: 0 none;
    }
}

@media only screen and (max-width: 575px) {
    .btn-inc,
    .btn-dec {
        height: 30px;
        width: 30px;
    }

    .ant-page-header {
        padding: 16px 20px !important;
    }

    .ant-layout-header {
        height: auto;
    }

    .ant-card-head {
        line-height: 1;
    }

    .ant-card-head-title {
        white-space: normal !important;
    }

    .pop-confirm .ant-btn {
        max-width: 60px;
        padding: 0 20px;
    }

    .pop-confirm.pop-confirm-right {
        margin-left: 320px !important;
    }

    .pop-confirm.pop-confirm-bottom {
        margin-left: 95px !important;
    }

    /* Card Heading */
    .ant-card-head-wrapper {
        flex-flow: column;
        align-items: center;
    }

    .ant-card-head-wrapper .ant-card-extra {
        padding: 10px 0 16px !important;
        float: none;
        margin: 0;
        flex-flow: column;
    }

    .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger + .card-nav {
        margin-bottom: 8px;
    }

    .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger {
        margin: 0 !important;
    }

    .ant-card-head-wrapper .ant-card-head-title {
        padding: 20px 0 0px !important;
    }

    .card-nav ul li.active:before {
        display: none;
    }

    .ant-card-head-title > div {
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    .ant-card-head-title > div span {
        margin: 8px 0 0;
    }
}

.bmzxig .ant-table tr th:first-child,
.bmzxig .ant-table tr td:first-child {
    padding-right: 15px;
}

/* Emprt */

.ant-empty .ant-empty-footer button {
    padding: 0px 15.58px !important;
}

/* Add Event Modal */
.addEvent-modal .ant-modal-header {
    padding: 20px 25px;
}

.addEvent-modal .ant-modal-header .ant-modal-close-x svg {
    color: #5a5f7d;
}

.addEvent-modal .ant-modal-header .ant-modal-title {
    font-size: 15px;
    font-weight: 500;
    color: #272b41;
}

.addEvent-modal .ant-modal-body {
    padding: 23px 25px;
}

/* Event Dropdown */

.event-dropdown {
    min-width: auto !important;
    max-width: 450px;
    padding: 0 !important;
    margin: 2px 0 0 !important;
    box-shadow: 0 10px 40px #9299b820 !important;
}

.event-dropdown div {
    border-radius: 8px;
}

.event-dropdown .ant-card {
    width: 100% !important;
    margin-bottom: 0 !important;
}

@media only screen and (max-width: 479px) {
    .placement-confirm {
        display: flex;
        flex-wrap: wrap;
        margin: -5px -10px 0 -5px;
    }

    .placement-confirm .pop-confirm {
        flex: 0 0 50%;
    }

    .pop-confirm .ant-btn {
        display: block;
        padding: 0 20px;
        max-width: 80px;
        min-width: 80px;
        margin: 10px;
    }

    .pop-confirm.pop-confirm-right {
        margin-left: 0px !important;
    }

    .pop-confirm.pop-confirm-top {
        margin-left: 0px !important;
    }

    .pop-confirm.pop-confirm-bottom {
        margin-left: 0px !important;
        margin-top: 0px !important;
    }
}

@media only screen and (max-width: 400px) {
    .ant-select {
        width: 100% !important;
    }

    .rdrDefinedRangesWrapper {
        width: 100% !important;
    }

    .rdrDateRangePickerWrapper {
        flex-wrap: wrap;
    }

    .atbd-top-dropdwon {
        width: 280px;
        min-width: 180px;
    }

    .atbd-top-dropdwon .atbd-top-dropdwon__title {
        min-height: 40px;
    }

    .atbd-top-dropdwon .atbd-top-dropdwon__nav li:not(:last-child) {
        margin-bottom: 10px;
    }

    .atbd-top-dropdwon .atbd-top-dropdwon__nav li a {
        padding: 10px 0px;
    }

    .atbd-top-dropdwon .atbd-top-dropdwon__content img {
        margin-right: 15px;
    }

    .atbd-top-dropdwon .atbd-top-dropdwon__content figcaption {
        margin-left: 0;
    }

    .atbd-top-dropdwon
        .atbd-top-dropdwon__content
        figcaption
        .atbd-top-dropdwonText {
        min-width: 155px;
    }

    .ant-drawer-content-wrapper {
        width: 260px !important;
    }

    .rdrCalendarWrapper.rdrDateRangeWrapper {
        margin-top: 0;
    }
}

@media only screen and (max-width: 379px) {
    .ant-card-head-wrapper .ant-card-extra .ant-radio-button-wrapper {
        height: 32px !important;
        line-height: 30px !important;
    }

    .ant-notification-notice {
        width: 275px;
    }
}

@media (max-width: 991px) {
    .mail-sideabr {
        box-shadow: 0 0 10px #00000020;
    }
}

/* Invoice Print Style */
@media print {
    .invoice-area .ant-page-header {
        display: none;
    }

    .customizer-trigger {
        display: none;
    }
}

/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff !important;
} */

/* custom label */

/* card spin loader */

.ant-card-body .sd-spin div,
.ant-card-body .spin div {
    position: relative;
}

.ant-card-body .sd-spin,
.ant-card-body .spin {
    height: 200px;
}

.ant-card-body {
    position: relative;
}

.ant-card-body .sd-spin .ant-spin,
.ant-card-body .spin .ant-spin {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 200px;
}

.starActive svg {
    color: red;
}

/* vectormap zoom in / zoom out */

.jvectormap-zoomin,
.jvectormap-zoomout {
    width: 27px;
    height: 27px;
    background: none;
    color: #5a5f7d;
    border: 1px solid #f1f2f6;
    padding: 0;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: auto;
    right: 0;
    top: auto;
    background-color: #fff;
}

.jvectormap-zoomin {
    border-radius: 6px 6px 0 0;
    bottom: 36px;
}

.jvectormap-zoomout {
    border-radius: 0 0 6px 6px;
    bottom: 10px;
}

.jvectormap-tip {
    padding: 7px 12px;
    border: 0 none;
    font-size: 12px;
    background: #272b41;
}

.btn-rtl {
    width: 60px;
    height: 60px;
    border: 1px solid #fff;
    background: #4347d9;
    color: #fff;
    position: fixed;
    right: 0;
    top: 50%;
    margin-top: -30px;
    z-index: 99999999;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* masonry-grid start from here */

.my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px;
    /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 10px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
}

.common-ul {
    padding: 5px 0;
    padding-left: 10px;
}

.display {
    display: none;
}

li.active > .display {
    display: block;
}

/* rtl section from here */
html[dir='rtl'] .ant-card-head-title span {
    margin-right: 15px;
}

html[dir='rtl'] span[aria-label='arrow-right'] svg,
html[dir='rtl'] span[aria-label='arrow-left'] svg {
    transform: rotateY(180deg);
}

html[dir='rtl'] .ant-menu.ant-menu-vertical {
    text-align: right !important;
}

html[dir='rtl']
    .ant-menu.ant-menu-vertical
    .ant-menu-submenu-placement-rightTop {
    left: -170px !important;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-vertical-left {
    text-align: right;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-submenu-arrow {
    right: auto;
    left: 16px;
    transform: rotateY(180deg);
}

html[dir='rtl'] .ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 15px !important;
}

:root {
    --blue: #0aa9e1;
    --green: #0ae1a0;
    --black: #000000;
    --gold: #fed7aa;
    --gold-trans: #fdf9f4;
    --red: #eb5757;
    --transparant: transparant;
    --c-0f151d: #0f151d;
    --c-334155: #334155;
    --c-d7e2f2: #d7e2f2;
    --c-f3f8fe: #f3f8fe;
    --c-99a3b8: #99a3b8;
    --c-ffffff66: #ffffff66;
    --c-fff33: #ffffff33;
    --c-667085: #667085;
    --c-e1e6ef: #e1e6ef;
    --c-344255: #344255;
    --c-101828: #101828;
    --c-331a: #3341551a;
    --c-e8e8e8: #e8e8e8;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poly&display=swap');

.poppins {
    font-family: 'Poppins', sans-serif;
}

.poly {
    font-family: 'Poly', serif;
}

.landing-page-body {
    overflow-x: hidden !important;
}

.cp {
    cursor: pointer;
}

.border-1-md-e1e6ef {
    border: 1px solid transparent !important;
}

.border-xs-e1e6ef {
    border: 1px solid #e1e6ef;
}

@media only screen and (min-width: 320px) {
    html {
        font-size: 12px;
    }

    .f-100 {
        font-size: 3.25rem !important;
    }

    .btn.btn-create-acc,
    .btn.btn-create-acc:hover {
        padding: 11px 100px !important;
    }

    .f-mobile-16 {
        font-size: 16px !important;
    }
}

@media only screen and (min-width: 768px) {
    .right-modal-content {
        width: 580px !important;
        min-height: 100vh;
        height: auto;
    }

    .px-md-24 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .pt-md-24 {
        padding-top: 1.5rem !important;
    }

    .pt-md-24px {
        padding-top: 24px !important;
    }

    .px-md-16 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .pl-md-120 {
        padding-left: 7.5rem !important;
    }

    .pr-md-40 {
        padding-right: 2.5rem !important;
    }

    .pr-md-8.px-0px,
    .pr-md-8 {
        padding-right: 8px !important;
    }

    .pl-md-8 {
        padding-left: 8px !important;
    }

    .pl-125 {
        padding-left: 7.8125rem !important;
    }

    .px-43 {
        padding-left: 43px !important;
        padding-right: 43px !important;
    }

    .max-w-md-255 {
        max-width: 15.9375rem !important;
    }

    .max-w-md-235 {
        max-width: 14.6875rem !important;
    }

    .max-w-md-470 {
        max-width: 29.375rem;
    }

    /*.max-w-md-605 {*/
    /*  max-width: 37.8125rem !important;*/
    /*}*/

    .max-w-md-976 {
        max-width: 61rem !important;
    }

    .bg-md-f8f9f9 {
        background: #f8f9f9;
    }
}

@media only screen and (max-width: 768px) {
    .l-60 {
        left: 12px !important;
    }
}

@media only screen and (min-width: 960px) {
    html {
        font-size: 14px;
    }

    .pl-md-133 {
        padding-left: 8.3125rem !important;
    }

    .pr-md-120 {
        padding-right: 7.5rem !important;
    }

    .arrow-inner .date-label,
    .arrow-inner .react-calendar__navigation__label {
        /* display: none; */
    }
}

@media only screen and (min-width: 1240px) {
    html {
        font-size: 16px;
    }

    .w-md-934 {
        width: 58.375rem !important;
    }

    .h-md-572 {
        height: 35.75rem !important;
    }

    .gap-md-261 {
        gap: 16.3125rem;
    }

    .bg-md-ff {
        background: var(--bs-white);
    }

    .br-top-bottom-left-md-50 {
        border-radius: 50px 0px 0px 50px !important;
    }

    .border-radius-md-0 {
        border-radius: 0 !important;
    }

    /* Padding MD Start*/

    .px-md-120 {
        padding-left: 7.5rem !important;
        padding-right: 7.5rem !important;
    }

    .pt-md-24 {
        padding-top: 1.5rem !important;
    }

    .py-md-36 {
        padding-top: 36px;
        padding-bottom: 36px;
    }

    .pt-md-11 {
        padding-top: 0.6875rem !important;
    }

    .pt-md-70 {
        padding-top: 4.375rem !important;
    }

    .pt-md-120 {
        padding-top: 7.5rem !important;
    }

    .pl-md-133 {
        padding-left: 8.3125rem !important;
    }

    .pr-md-120 {
        padding-right: 7.5rem !important;
    }

    .pr-md-7-5 {
        padding-right: 0.4688rem !important;
    }

    .pl-md-7-5 {
        padding-left: 0.4688rem !important;
    }

    .pb-md-200 {
        padding-bottom: 12.5rem !important;
    }

    .py-md-29 {
        padding-top: 1.8125rem !important;
        padding-bottom: 1.8125rem !important;
    }

    .pr-md-32 {
        padding-right: 2rem !important;
    }

    .pr-md-34 {
        padding-right: 2.125rem !important;
    }

    .pr-md-61 {
        padding-right: 3.8125rem !important;
    }

    .pl-md-35 {
        padding-left: 2.1875rem !important;
    }

    .py-md-11 {
        padding-top: 0.6875rem !important;
        padding-bottom: 0.6875rem !important;
    }

    .px-md-20 {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
    }

    .py-md-24 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .pr-md-57 {
        padding-right: 3.5625rem !important;
    }

    .pr-md-77 {
        padding-right: 4.8125rem !important;
    }

    .pt-md-12 {
        padding-top: 0.75rem !important;
    }

    .pb-md-110 {
        padding-bottom: 6.875rem !important;
    }

    .px-md-45 {
        padding-left: 2.8125rem !important;
        padding-right: 2.8125rem !important;
    }

    .pb-md-30 {
        padding-bottom: 1.875rem !important;
    }

    .pt-md-10 {
        padding-top: 0.625rem !important;
    }

    .pb-md-20 {
        padding-bottom: 1.25rem !important;
    }

    .py-md-80 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .pt-md-100 {
        padding-top: 6.25rem !important;
    }

    .py-md-100 {
        padding-top: 6.25rem !important;
        padding-bottom: 6.25rem !important;
    }

    .pb-md-100 {
        padding-bottom: 6.25rem !important;
    }

    .pb-md-40 {
        padding-bottom: 2.5rem !important;
    }

    .pb-md-60 {
        padding-bottom: 3.75rem !important;
    }

    .pl-md-30 {
        padding-left: 1.875rem !important;
    }

    .pl-md-44 {
        padding-left: 2.75rem !important;
    }

    .pb-md-43 {
        padding-bottom: 2.6875rem !important;
    }

    .pr-md-16 {
        padding-right: 1rem !important;
    }

    /* Padding MD End */

    /* Margin MD Start */

    .mr-md-40 {
        margin-right: 2.5rem !important;
    }

    .ml-md-8 {
        margin-left: 0.5rem !important;
    }

    .mb-md-24 {
        margin-bottom: 1.5rem !important;
    }

    .mb-md-50 {
        margin-bottom: 3.125rem !important;
    }

    .mt-md--46 {
        margin-top: -2.875rem !important;
    }

    .mb-md--20 {
        margin-bottom: -20rem;
    }

    /* Margin MD End */
}

@media only screen and (min-width: 1400px) {
    .pl-60px {
        padding-left: 3.75rem !important;
    }

    .px-lg-29 {
        padding-left: 1.8125rem !important;
        padding-right: 1.8125rem !important;
    }

    .w-732px {
        width: 732px !important;
    }

    .max-w-881px {
        max-width: 881px !important;
    }

    .max-w-1159px {
        max-width: 1159px !important;
    }

    .max-w-1385px {
        max-width: 1385px !important;
    }

    .max-w-1504px {
        max-width: 1504px !important;
    }

    .max-w-1684px {
        max-width: 1684px !important;
    }

    .max-w-859 {
        max-width: 53.6875rem !important;
    }

    .max-w-847 {
        max-width: 52.9375rem !important;
    }

    .max-w-836 {
        max-width: 52.25rem !important;
    }

    .max-w-974 {
        max-width: 60.875rem !important;
    }

    .max-w-1023 {
        max-width: 63.9375rem !important;
    }

    .max-w-305 {
        max-width: 19.0625rem !important;
    }

    .max-w-327 {
        max-width: 20.4375rem !important;
    }

    .max-w-350 {
        max-width: 21.875rem !important;
    }

    .min-w-473 {
        min-width: 29.5625rem !important;
    }

    .min-w-369 {
        min-width: 23.0625rem !important;
    }

    .min-max-w-455 {
        min-width: 28.4375rem !important;
        max-width: 28.4375rem !important;
    }
}

/* font-size Start */

.f-8 {
    font-size: 8px;
}

.f-10 {
    font-size: 0.625rem;
}

.f-12 {
    font-size: 0.75rem;
}

.f-13 {
    font-size: 13px;
}

.f-14 {
    font-size: 0.875rem;
}

.f-15 {
    font-size: 15px;
}

.f-16 {
    font-size: 1rem;
}

.f-16px {
    font-size: 16px;
}

.f-18 {
    font-size: 1.125rem !important;
}

.f-20 {
    font-size: 1.25rem !important;
}

.f-20px {
    font-size: 20px !important;
}

.f-22 {
    font-size: 1.375rem;
}

.f-22px {
    font-size: 22px;
}

.f-24 {
    font-size: 1.5rem;
}

.f-25 {
    font-size: 1.5625rem;
}

.f-30 {
    font-size: 1.875rem;
}

.f-36 {
    font-size: 2.25rem;
}

.f-36px {
    font-size: 36px;
}

.f-42 {
    font-size: 2.625rem;
}

.f-50 {
    font-size: 3.125rem;
}

.f-60 {
    font-size: 3.75rem;
}

.f-70 {
    font-size: 4.375rem;
}

.f-100 {
    font-size: 6.25rem;
}

/* font-size End */

.lh-0 {
    line-height: 0 !important;
}

.lh-normal {
    line-height: normal !important;
}

.lh-16 {
    line-height: 1rem;
}

.lh-17 {
    line-height: 1.0625rem;
}

.lh-20 {
    line-height: 1.25rem;
}

.lh-23 {
    line-height: 1.4375rem;
}

.lh-24 {
    line-height: 1.5rem;
}

.lh-26 {
    line-height: 1.625rem;
}

.lh-32 {
    line-height: 2rem;
}

.lh-36 {
    line-height: 2.25rem;
}

.lh-40 {
    line-height: 2.5rem;
}

.lh-48 {
    line-height: 3rem;
}

.lh-120 {
    line-height: 7.5rem;
}

/* Padding Start */

.px-0px {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.pb-6 {
    padding-bottom: 0.375rem;
}

.pr-6 {
    padding-right: 0.375rem !important;
}

.px-6 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
}

.pl-6 {
    padding-left: 0.375rem !important;
}

.p-12 {
    padding: 12px !important;
}

.pl-10 {
    padding-left: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-11 {
    padding-right: 0.6875rem;
}

.pr-13 {
    padding-right: 0.8125rem;
}

.pr-16 {
    padding-right: 1rem;
}

.pr-12 {
    padding-right: 0.75rem !important;
}

.py-7 {
    padding-top: 7px;
    padding-bottom: 7px;
}

.py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.py-14 {
    padding-top: 14px;
    padding-bottom: 14px;
}

.py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.pl-18 {
    padding-left: 8px;
}

.pb-9 {
    padding-bottom: 0.5625rem;
}

.py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.pr-8 {
    padding-right: 0.5rem !important;
}

.pr-26 {
    padding-right: 26px !important;
}

.pt-3px {
    padding-top: 3px;
}

.pl-20 {
    padding-left: 1.25rem;
}

.pr-57 {
    padding-right: 3.5625rem;
}

.pr-17 {
    padding-right: 1.0625rem;
}

.pr-20 {
    padding-right: 1.25rem;
}

.pr-23 {
    padding-right: 23px !important;
}

.px-20 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.px-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
}

.py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
}

.pt-24 {
    padding-top: 24px;
}

.pl-24 {
    padding-left: 24px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pb-23 {
    padding-bottom: 23px !important;
}

.pb-28 {
    padding-bottom: 28px !important;
}

.pb-7 {
    padding-bottom: 7px;
}

.pb-36px {
    padding-bottom: 36px;
}

.pb-24 {
    padding-bottom: 24px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-36 {
    padding-top: 36px;
}

.py-36 {
    padding-top: 36px;
    padding-bottom: 36px;
}

.pr-77 {
    padding-right: 4.8125rem;
}

.pb-12 {
    padding-bottom: 0.75rem !important;
}

.pb-8 {
    padding-bottom: 0.5rem !important;
}

.pt-32 {
    padding-top: 2rem;
}

.pt-40 {
    padding-top: 2.5rem;
}

.pt-44 {
    padding-top: 44px;
}

.pt-49px {
    padding-top: 49px !important;
}

.pb-41 {
    padding-bottom: 2.5625rem;
}

.pb-22 {
    padding-bottom: 1.375rem;
}

.p-16 {
    padding: 1rem;
}

.p-16px {
    padding: 16px;
}

.px-16 {
    padding-right: 1rem;
    padding-left: 1rem;
}

.px-18 {
    padding-right: 18px !important;
    padding-left: 18px !important;
}

.px-9 {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.pl-6 {
    padding-left: 0.375rem;
}

.p-6 {
    padding: 6px !important;
}

.p-15 {
    padding: 0.9375rem !important;
}

.p-20 {
    padding: 1.25rem !important;
}

.px-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
}

.py-4rem {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.pr-4 {
    padding-right: 0.25rem !important;
}

.p-30-sm-8,
.p-30 {
    padding: 1.875rem !important;
}

.p-40-sm-12,
.p-40 {
    padding: 2.5rem !important;
}

.pt-14 {
    padding-top: 0.875rem;
}

.pb-14 {
    padding-bottom: 0.875rem;
}

.pb-15 {
    padding-bottom: 0.875rem;
}

.pb-16 {
    padding-bottom: 1rem;
}

.pb-32 {
    padding-bottom: 2rem;
}

.pb-35 {
    padding-bottom: 2.1875rem;
}

.pb-11 {
    padding-bottom: 0.6875rem;
}

.pb-18 {
    padding-bottom: 1.125rem;
}

.pb-25 {
    padding-bottom: 1.5625rem;
}

.pb-20 {
    padding-bottom: 1.25rem;
}

.pb-30 {
    padding-bottom: 1.875rem !important;
}

.pb-65 {
    padding-bottom: 4.0625rem;
}

.pr-87 {
    padding-right: 87px;
}

.px-48 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.pt-4px {
    padding-top: 4px !important;
}

.pb-2px {
    padding-bottom: 2px;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-60 {
    padding-bottom: 60px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

.pb-100 {
    padding-bottom: 100px !important;
}

/* Padding End */

/* Margin Start */

.mr-8 {
    margin-right: 8px;
}

.mb-44 {
    margin-bottom: 2.75rem;
}

.mb-4px {
    margin-bottom: 4px;
}

.mb-9 {
    margin-bottom: 9px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mx-20 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
}

.mx-24 {
    margin-right: 24px !important;
    margin-left: 24px !important;
}

.mb-24 {
    margin-bottom: 1.5rem;
}

.mb-24px {
    margin-bottom: 24px;
}

.mb-16 {
    margin-bottom: 1rem;
}

.mb-8 {
    margin-bottom: 0.5rem;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-14 {
    margin-bottom: 0.875rem;
}

.mb-20 {
    margin-bottom: 1.25rem;
}

.mb-82 {
    margin-bottom: 5.125rem;
}

.my-6 {
    margin-top: 6px;
    margin-bottom: 6px;
}

.my-14 {
    margin-top: 14px;
    margin-bottom: 14px;
}

.my-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-27 {
    margin-top: 27px;
    margin-bottom: 27px;
}

.my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

/* Margin End */

/* height Start */

.h-2 {
    height: 2rem;
}

.h-8 {
    height: 0.5rem;
}

/* height End */

.box-sizing-content-box {
    box-sizing: content-box;
}

.text-danger {
    color: var(--red);
}

.input-filed,
.input-filed:focus {
    color: var(--c-0f151d) !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    border-radius: 44px !important;
    border: 1px solid #e1e6ef !important;
    background: #fff !important;
    padding: 11px 26px !important;
    box-shadow: 0 0 0 0 !important;
}

.btn-country,
.btn-country:hover {
    color: var(--c-0f151d) !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    padding: 11px 16px 11px 26px !important;
    border-radius: 44px !important;
    border: 1px solid #e1e6ef !important;
    background: #fff !important;
    width: -webkit-fill-available !important;
    text-align: start !important;
}

.c-101828 {
    color: var(--c-101828);
}

.c-0f151d {
    color: var(--c-0f151d) !important;
}

.border-y-0 {
    border-top: 0;
    border-bottom: 0;
}

.border-l-0 {
    border-left: 0;
}

.border-r-e1e6ef {
    border-right: 2px solid #e1e6ef;
}

.form-check .form-check-input {
    margin-top: 0;
}

.form-check-input,
.form-check-input:focus {
    border-radius: 8px !important;
    width: 24px !important;
    height: 24px !important;
    box-shadow: 0 0 0 transparent !important;
}

.form-check-input:checked {
    background-color: #334155;
    border-color: #334155;
}

.box-shadow-0 {
    box-shadow: 0 0 0 !important;
}

.btn.btn-create-acc,
.btn.btn-create-acc:hover {
    border-radius: 40px;
    color: var(--bs-white);
    background: var(--c-334155);
    padding: 11px 130px;
    width: 100%;
    font-size: 16px;
}

.country-dropdown-menu {
    margin-top: 10px !important;
    width: 327px;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    box-shadow: -2px 2px 12px 2px rgba(21, 30, 42, 0.08);
    border-radius: 16px;
    background: #fff;
}

.country-dropdown-input {
    margin: 0 16px;
    width: -webkit-fill-available;
    padding: 4px 16px;
    border: 1px solid #ccc;
    border-radius: 44px;
}

.country-name-dropdown-menu {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    box-shadow: -2px 2px 12px 2px rgba(21, 30, 42, 0.08);
    border-radius: 16px;
    background: #fff;
}

.success-badge {
    background-color: #4caf50;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
}

.error-badge {
    background-color: #ff0000;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
}

.btn-country-num,
.btn-country-num:hover {
    color: var(--c-0f151d);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
    border: 0 !important;
    background: #fff;
    width: -webkit-fill-available;
    text-align: start;
}

.password-error-text {
    position: absolute;
    color: darkred;
    font-size: 12px;
    left: 27px;
}

.centered-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.min-w-443 {
    min-width: 443px;
}

/* Utilitiy classes */

.spin {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.05);
}

.d-flex {
    display: flex;
}

.flex-grid .flex-grid-child {
    padding: 0 12px;
}

.align-center-v {
    display: flex;
    align-items: center;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.line-height-0 {
    line-height: 0;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.status {
    display: inline !important;
    font-size: 12px;
    font-weight: 500;
    background-color: #868eae10;
    padding: 4.5px 11.85px;
    border-radius: 15px;
}

.status.Success {
    background-color: #20c99710;
    color: #20c997;
}

.status.warning {
    background-color: #fa8b0c10;
    color: #fa8b0c;
}

.status.error {
    background-color: #ff4d4f10;
    color: #ff4d4f;
}

ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

/* spacing classes */

.m-0 {
    margin: 0 !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-32 {
    margin-bottom: 32px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pl-0 {
    padding-left: 0 !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.pr-0 {
    padding-right: 0 !important;
}

/* default card style */

.ant-card {
    box-shadow: 0 5px 20px #9299b803;
}

.ant-card-head {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.ant-card-head-title span {
    display: inline-block;
    margin-left: 15px;
    font-size: 11px;
    font-weight: 500;
    color: #868eae;
}

.ant-card-head .ant-card-extra {
    display: flex;
    align-items: center;
}

.ant-card-head .ant-card-extra a {
    color: #868eae;
}

.ant-card-extra .ant-dropdown-trigger {
    line-height: 0;
    order: 1;
    margin-left: 20px;
}

.sDash_unresizable {
    resize: none;
}

/* ant radio group */

.ant-radio-button-wrapper-checked {
    color: #fff !important;
}

/* card nav */

.card-nav ul {
    list-style: none;
    display: flex;
    margin: 0 -8px !important;
}

.card-nav ul li {
    margin: 0 8px !important;
    position: relative;
}

.card-nav ul li a {
    font-weight: 500;
    color: #868eae;
    font-size: 12px;
}

.card-nav ul li.active a {
    color: #ffffff;
    font-weight: 500;
}

.card-nav ul li.active:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: #ffffff;
    left: 0;
    bottom: -19px;
}

/* chart growth upward */

.growth-downward p,
.growth-upward p {
    font-size: 14px;
    color: #868eae;
    margin: 0;
}

.growth-downward h1 sub svg,
.growth-upward h1 sub svg {
    position: relative;
    top: 2px;
    font-size: 14px;
    font-weight: 600;
    left: 5px;
    bottom: 0;
}

/*
.growth-downward h1, .growth-upward h1 {
  font-size: 22px;
  margin: 6px 0 0;
} */

.growth-downward h1 sub {
    color: #ff4d4f;
}

.growth-upward h1 sub {
    color: #20c997;
}

/* Chart */

.chart-label {
    display: flex;
}

.chart-label .chart-label__single {
    align-items: center;
}

.chart-label .chart-label__single:not(:last-child) {
    margin-right: 40px;
}

.chart-label .chart-label__single p {
    margin: 0;
    color: #868eae;
}

/* revenue doughnut */

.revenue-doughnut {
    display: flex;
    justify-content: center;
}

.revenue-doughnut > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.revenue-doughnut .rd-labels {
    width: 100%;
    margin-top: 30px;
}

.revenue-chat-percentage span {
    display: block;
}

.revenue-chat-percentage span:not(:last-child) {
    margin-bottom: 15px;
}

/* progressbars */

.ant-progress {
    display: inline-flex !important;
    align-items: center;
}

.ant-progress > div {
    display: flex;
    flex-direction: column;
}

.ant-progress .ant-progress-outer {
    margin-right: 0 !important;
    padding-right: 0 !important;
}

.revenue-chat-percentage span {
    display: block;
    font-size: 13px;
    color: #868eae;
}

.revenue-chat-percentage span:not(:last-child) {
    margin-bottom: 20px;
}

.ant-progress-status-warning .ant-progress-bg {
    background: #fa8b0c;
}

/* progress bars */

.progress-primary .ant-progress-bg {
    background: #ffffff;
}

.progress-secondary .ant-progress-bg {
    background: #ff69a5;
}

.progress-success .ant-progress-bg {
    background: #20c997;
}

.progress-success .progress-info .ant-progress-bg {
    background: #2c99ff;
}

.progress-warning .ant-progress-bg {
    background: #fa8b0c;
}

.progress-danger .ant-progress-bg {
    background: #ff4d4f;
}

/* color classes */

.color-primary {
    color: #ffffff;
}

.color-secondary {
    color: #ff69a5;
}

.color-info {
    color: #2c99ff;
}

.color-warning {
    color: #fa8b0c;
}

.color-success {
    color: #20c997;
}

.color-danger {
    color: #ff4d4f;
}

.color-dark {
    color: #272b41;
}

.color-error {
    color: #f5222d;
}

.color-gray {
    color: #5a5f7d;
}

/* Button Styles */

.ant-btn.ant-btn-light:focus {
    background: #fff;
    color: #5a5f7d;
}

.button-example .ant-btn {
    margin: 4px;
}

.button-example .ant-btn-group .ant-btn {
    margin: 0;
}

.ant-btn-white {
    color: #5a5f7d !important;
    border-color: #e3e6ef !important;
}

.ant-btn-white:hover {
    color: #ffffff !important;
    background-color: #fff !important;
    border-color: #fff;
}

.ant-btn-white:focus {
    background-color: transparent !important;
}

.ant-btn-link {
    background-color: #fff;
    border-color: #fff;
    color: #ffffff;
}

.ant-btn-link:hover {
    border-color: #fff;
    background: #fff;
}

.ant-btn-light,
.ant-btn-light:hover,
.ant-btn-link:hover,
.ant-btn-dashed {
    color: #5a5f7d !important;
}

.ant-btn-light {
    border-color: #e3e6ef;
    background: #f4f5f7 !important;
}

.ant-btn-light.btn-outlined {
    background: transparent !important;
}

.ant-btn-light.btn-transparent {
    background: #f4f5f715;
}

.ant-btn-dashed {
    border-width: 1px !important;
}

.ant-btn-dashed:hover {
    color: #ffffff;
    border: 1px dashed #ffffff;
}

.ant-btn-primary[disabled] {
    color: #fff;
    background: #ffffff60;
}

.ant-btn-light[disabled] {
    background: #fff;
}

.ant-btn-round.ant-btn-sm {
    height: 38px;
}

.ant-btn-white[disabled] {
    background-color: transparent;
    opacity: 0.6;
}

.ant-btn-white[disabled]:hover {
    color: #5a5f7d !important;
}

.ant-btn-primary[disabled]:hover {
    color: #fff !important;
    background: #ffffff60 !important;
}

.btn-icon {
    padding: 0 13px;
}

.btn-inc,
.btn-dec {
    height: 38px;
    width: 38px;
    font-size: 20px;
    padding: 0 12px !important;
    border-radius: 10px !important;
    border: 0 none;
}

.btn-inc:hover,
.btn-dec:hover {
    background: #ffffff10 !important;
    border: 0 none !important;
}

.btn-inc:hover i,
.btn-inc:hover svg,
.btn-dec:hover i,
.btn-dec:hover svg {
    color: #ffffff;
}

/* input styles */

.ant-form-item-label > label {
    font-weight: 500;
}

.ant-picker-input > input::placeholder {
    color: #adb4d2 !important;
}

/* Calendar Styles */

.ant-picker-calendar-header .ant-select-selector {
    height: 32px !important;
}

.ant-picker-calendar-header .ant-select-selection-search-input {
    height: 30px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 30px !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #fff !important;
    color: #ffffff !important;
}

/* pagination */

.ant-pagination
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 32px !important;
}

.ant-pagination-item-active {
    background-color: #ffffff !important;
}

.ant-pagination-item-active a {
    color: #fff !important;
}

.ant-pagination .ant-pagination-options .ant-select-selection-item {
    font-size: 13px;
    line-height: 30px !important;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
    height: 30px;
    line-height: 30px;
}

.ant-pagination
    .ant-pagination-options
    .ant-pagination-options-quick-jumper
    input {
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-options .ant-select-selector {
    border: 1px solid #f1f2f6 !important;
    background-color: #fff;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
    color: #5a5f7d !important;
    line-height: 2.6;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-link {
    display: block;
}

.ant-pagination-prev,
.ant-pagination-next {
    line-height: 28px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    border: 0 none !important;
}

.ant-pagination .ant-pagination-item a {
    color: #5a5f7d;
}

.ant-pagination
    .ant-select-single
    .ant-select-selector
    .ant-select-selection-item {
    line-height: 30px !important;
    color: #5a5f7d;
}

.ant-pagination
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100% !important;
}

.ant-pagination {
    margin: -4px -12px !important;
}

.ant-pagination-item,
.ant-pagination-options,
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-next {
    margin: 4px !important;
}

.ant-table-pagination {
    margin-top: 30px !important;
}

/* Wizard Modal */
.submission-successModal {
    text-align: center;
}

.submission-successModal .icon-success {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px solid #20c997;
}

.submission-successModal p {
    margin-bottom: 0;
}

.submission-successModal .icon-success svg {
    color: #20c997;
}

.submission-successModal .submission-action {
    margin: 30px -5px -5px -5px;
}

.submission-successModal .submission-action button {
    margin: 5px;
    height: 38px;
}

/* Tree Select */

.ant-tree-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 42px !important;
    line-height: 40px;
}

.ant-tree-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100% !important;
}

.ant-tree-select.ant-select-single
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 40px !important;
}

/* radio style */

.ant-radio-checked .ant-radio-inner {
    border-width: 5px !important;
}

.ant-radio-inner::after {
    content: none !important;
}

/* Statistics */

.ant-statistic .ant-statistic-title {
    color: #9299b8;
}

.ant-statistic .ant-statistic-content span {
    font-size: 20px;
}

/* Steps */

.steps-action.justify-content-center {
    justify-content: center !important;
}

/* rate */

.ant-rate-star.ant-rate-star-zero span svg {
    color: #c6d0dc;
}

.ant-rate-star:not(:last-child) {
    margin-right: 2px !important;
}

.ant-rate-text {
    color: #5a5f7d;
}

/* result */

.ant-result-icon {
    margin-bottom: 20px !important;
}

.ant-result-title {
    font-weight: 500;
    margin-bottom: 10px;
}

.ant-result-extra {
    height: 34px;
    padding: 4px 10.72px;
}

.ant-result-content .ant-typography strong {
    font-weight: 500;
}

.ant-result-content .ant-typography:last-child {
    margin-bottom: 0;
}

/* form select */

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 33px !important;
}

.ant-select-multiple .ant-select-selector {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.sDash_fullwidth-select {
    width: 100%;
}

/* Nasted Comments Styles */

.nested-comment-wrapper .comment-title {
    font-size: 12px;
    padding-bottom: 10px;
    margin-bottom: 22px;
    border-bottom: 1px solid #e3e6ef;
}

/* calendar style */

.events {
    list-style: none;
    margin: 0;
    padding: 0;
}

.events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
}

.notes-month {
    text-align: center;
    font-size: 28px;
}

.notes-month section {
    font-size: 28px;
}

/* Breadcrumb demo */

.demo {
    margin: 16px;
}

.demo-nav {
    height: 30px;
    line-height: 30px;
    margin-bottom: 16px;
    background: #f8f8f8;
}

.demo-nav a {
    line-height: 30px;
    padding: 0 8px;
}

.app-list {
    margin-top: 16px;
}

/* Modal */

.ant-modal .ant-modal-content {
    border-radius: 10px;
}

.ant-modal .ant-modal-header {
    border-radius: 10px 10px 0 0;
}

.ant-modal-body p:last-child {
    margin-bottom: 0;
}

.project-modal {
    padding: 5px !important;
}

.project-modal .projects-members {
    margin-top: -12px;
}

.project-modal .ant-form-item-control-input {
    margin-top: 10px;
    min-height: auto;
}

.project-modal .ant-select-selector {
    border-color: #e3e6ef;
}

.project-modal-footer {
    padding: 10px !important;
    text-align: left !important;
}

.sDash_addTask-modal {
    max-width: 390px;
}

.sDash_addTask-modal .ant-modal-header {
    border-bottom: 0 none;
    padding-bottom: 0 !important;
}

.sDash_addTask-modal .ant-modal-header .ant-modal-title {
    font-size: 16px;
    font-weight: 500;
    color: #272b41;
}

.sDash_addTask-modal .sDash_addTask-modal-inner .ant-form-item {
    margin-bottom: 15px;
}

.sDash_addTask-modal .ant-modal-header .ant-modal-close-x svg {
    color: #9299b8;
}

.sDash_addTask-modal .ant-form-item-control-input {
    min-height: 36px;
}

.sDash_addTask-modal input::placeholder,
.sDash_addTask-modal textarea::placeholder {
    color: #9299b8;
}

.sDash_addTask-modal input,
.sDash_addTask-modal textarea {
    padding: 6px 20px;
    border-radius: 5px;
}

.sDash_addTask-modal textarea {
    resize: none;
    min-height: 125px;
}

.sDash_addTask-modal .sDash-modal-actions {
    display: flex;
    justify-content: flex-end;
    margin: -6px;
}

.sDash_addTask-modal .sDash-modal-actions button {
    font-size: 14px;
    margin: 6px;
    height: 38px;
    border-radius: 5px;
}

/* slider */

.ant-slider-handle {
    margin-top: -6px !important;
}

/* tags */

.ant-tag {
    font-weight: 600;
    padding: 0 9.5px;
}

/* tabs */

.ant-tabs-tab span {
    display: flex;
    align-items: center;
}

/* list */

.ant-list-bordered {
    border-color: #e3e6ef !important;
}

.ant-list-item-meta {
    padding: 10px 24px;
    border-bottom: 1px solid #f1f2f6;
}

/* popover */

.ant-popover {
    position: fixed;
    z-index: 99999;
}

.ant-popover-inner {
    padding: 15px;
    box-shadow: 0 5px 20px #9299b820;
    border-radius: 0 0 6px 6px;
}

.ant-popover-inner .ant-popover-title {
    padding: 5px 10px 10px;
}

.ant-popover-inner .ant-popover-inner-content a {
    color: #5a5f7d;
}

/* Drawer */

.ant-drawer {
    z-index: 99999;
}

/* Select Dropdwon */

.ant-select-dropdown {
    padding: 18px 0 !important;
    box-shadow: 0 5px 20px #9299b820 !important;
    border-radius: 0 0 6px 6px !important;
}

.ant-select-item {
    min-height: 20px !important;
    padding: 4px 12px !important;
}

.ant-select-item-group {
    color: #9299b8;
}

.ant-select-item.ant-select-item-option-grouped {
    padding-left: 25px !important;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-active {
    background: #ffffff05;
}

.ant-select-dropdown
    .ant-select-item.ant-select-item-option-selected
    .ant-select-item-option-content {
    /* padding-left: 10px; */
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
    color: #ffffff;
    background: #ffffff06;
}

.ant-select-dropdown
    .ant-select-item.ant-select-item-option-selected
    .ant-select-item-option-content {
    color: black !important;
    font-weight: 500;
}

.ant-select-dropdown .ant-select-item .ant-select-item-option-content {
    transition: 0.3s;
    color: #5a5f7d;
}

/* mail props dropdown */

.mail-props {
    padding: 5px 25px;
    border: 0 none;
    background: #fff;
    /* box-shadow: 0 5px 40px rgba(146, 153, 184, 0.25); */
}

.mail-props li {
    display: flex;
    margin-bottom: 12px;
}

.mail-props li span:first-child {
    margin-right: 50px;
    min-width: 40px;
}

.mail-props li span:last-child {
    color: #5a5f7d;
}

.mail-props li:last-child {
    margin-bottom: 0;
}

.mail-props li span {
    color: #9299b8;
}

/* Basic Dropdwon */

.ant-dropdown {
    z-index: 950 !important;
    box-shadow: 0 5px 30px #9299b820 !important;
}

.ant-dropdown.wide-dropdwon {
    min-width: 140px !important;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more {
    min-width: 220px !important;
    box-shadow: 0 17px 20px #9299b820;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more a {
    padding: 10px 24px;
}

.ant-dropdown-menu {
    min-width: 200px;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 0;
}

.ant-dropdown-menu li {
    color: #5a5f7d;
    padding: 5px 25px;
}

.ant-dropdown-menu li:hover {
    background-color: #ffffff05;
}

.atbd-top-dropdwon .atbd-top-dropdwon__title {
    background: #f4f5f7;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    min-height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.atbd-top-dropdwon {
    width: 340px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav {
    height: 280;
    overflow: hidden;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav.notification-list {
    padding: 0 10px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li {
    width: 100%;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a {
    padding: 13px 10px;
    position: relative;
    width: 100%;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li:last-child {
    margin: 0;
}

.atbd-top-dropdwon a.btn-seeAll {
    position: relative;
    width: calc(100% + 30px);
    left: -15px;
    right: -15px;
    height: calc(100% + 15px);
    bottom: -15px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #ffffff;
    padding: 15px 0;
    border-radius: 0 0 6px 6px;
    background: #fff;
}

.atbd-top-dropdwon a.btn-seeAll:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 -15px 20px #9299b808;
    z-index: 1;
    content: '';
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a.btn-seeAll:hover:after {
    box-shadow: 0 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:hover {
    background: #fff;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:hover:after {
    opacity: 1;
    visibility: visible;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:after {
    position: absolute;
    left: -15px;
    right: -15px;
    top: 0;
    width: calc(100% + 30px);
    height: 100%;
    box-shadow: 0 15px 50px #9299b820;
    z-index: 1;
    content: '';
    opacity: 0;
    visibility: hidden;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon {
    width: 39.2px;
    height: 32px;
    margin-right: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon.bg-primary {
    background: #ffffff15;
    color: #ffffff;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon.bg-secondary {
    background: #ff69a515;
    color: #ff69a5;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon svg {
    width: 18px;
    height: 18px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.atbd-top-dropdwon .notification-text h1 {
    font-size: 14px;
    font-weight: 400;
    color: #5a5f7d;
    margin-bottom: 4px;
}

.atbd-top-dropdwon .notification-text h1 span {
    color: #ffffff;
    font-weight: 500;
    padding-left: 0;
}

.atbd-top-dropdwon .notification-text p {
    font-size: 12px;
    color: #adb4d2;
    margin-bottom: 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content img {
    max-width: 40px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption {
    margin: -4px 15px 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption .ant-badge-count {
    font-size: 8px;
    min-width: 16px;
    width: 16px;
    height: 16px;
    line-height: 6px;
    border-radius: 8px;
}

.atbd-top-dropdwon
    .atbd-top-dropdwon__content
    figcaption
    p.ant-scroll-number-only-unit.current {
    height: 15px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption h1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption h1 span {
    font-size: 12px;
    font-weight: 400;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.add-file-dropdown a {
    font-size: 14px;
    font-weight: 400;
}

.add-file-dropdown a svg {
    width: 14px;
}

.folder-dropdown {
    min-width: 200px !important;
}

/* Tooltip Styles */

.ant-tooltip .ant-tooltip-inner {
    min-height: 40px;
    padding: 6px 20px;
    font-size: 14px;
    color: #5a5f7d;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    align-items: center;
    border: 1px solid #f1f2f6;
    box-shadow: 0 8px 15px #9299b815;
}

.ant-tooltip .ant-tooltip-arrow {
    /* position: relative; */
}

.ant-tooltip .ant-tooltip-arrow:after {
    position: absolute;
    left: 50%;
    top: 12px;
    transform: translateX(-50%);
    width: 13px;
    height: 13px;
    background: #fff;
    content: '';
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
    width: 10px;
    height: 10px;
    background: #fff;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow .ant-tooltip-arrow-content {
    border: 1px solid #f1f2f6;
    box-shadow: 0 0;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
    top: -4.071068px;
}

/* Badge Styles */

.ant-badge.badge-success .ant-badge-count {
    background: #20c997;
}

.badge {
    font-size: 11px;
    font-weight: 500;
    padding: 0 6.5px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.badge.badge-primary {
    background: #ffffff10;
    color: #ffffff;
}

/* Cascade Styles */

.custom-cascade-render .ant-cascader-picker {
    width: 100%;
}

.ant-cascader-picker input::placeholder {
    color: #adb4d2;
}

/* Comment Styles */

.ant-comment-actions li {
    margin-bottom: 0 !important;
}

/* Radio Vertical Button */

.ant-radio-vertical .ant-radio-wrapper {
    display: block;
}

.ant-radio-vertical .ant-radio-wrapper:not(:last-child) {
    margin-bottom: 14px;
}

/* Select Tree */

.ant-select-tree-list .ant-select-tree-node-content-wrapper:hover {
    background-color: #bae7ff !important;
}

.ant-select-tree-list .ant-select-arrow svg {
    font-size: 10px;
}

.ant-tree-select.ant-select-multiple .ant-select-selection-item {
    border: 0 none;
    border-radius: 3px;
    background: #f4f5f7;
    color: #5a5f7d;
    font-weight: 500;
}

/* Ant Switch */

.ant-switch:after {
    width: 14px !important;
    height: 14px !important;
}

.ant-switch-small:after {
    width: 10px !important;
    height: 10px !important;
}

/* Time Picker */

.ant-picker {
    min-width: 250px;
}

/* Input Affix */

.ant-input-affix-wrapper > input.ant-input {
    padding-left: 5px;
}

.ant-input-affix-wrapper .ant-input-prefix svg {
    color: #e3e6ef;
}

/* Space Item */

.ant-space-item .ant-btn span {
    font-size: 14px;
}

/* Pop confirm */

.pop-confirm .ant-btn {
    max-width: 90px;
    padding: 0px 35.5px;
    height: 44px;
}

.pop-confirm-top {
    margin-bottom: 10px;
}

.pop-confirm-bottom {
    margin-top: 10px;
}

.pop-confirm-top .ant-btn:not(:last-child),
.pop-confirm-bottom .ant-btn:not(:last-child) {
    margin-right: 10px;
}

.pop-confirm-left .ant-btn:not(:last-child),
.pop-confirm-right .ant-btn:not(:last-child) {
    margin-bottom: 10px;
}

/* Ant Upload */

.ant-upload .ant-btn {
    font-size: 14px;
    border-radius: 5px;
}

.ant-upload.ant-upload-select-picture-card {
    border-color: #e3e6ef !important;
    border-radius: 5px !important;
    background-color: #f8f9fb !important;
}

.ant-upload.ant-upload-select-picture-card .anticon {
    margin-bottom: 8px;
}

.ant-upload .anticon svg {
    color: #9299b8;
}

.ant-upload-list {
    margin-top: 10px !important;
}

.sDash_upload-basic .ant-upload.ant-upload-select {
    width: 100%;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
}

.sDash_upload-basic .ant-upload.ant-upload-select .ant-upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sDash_upload-basic .sDash_upload-text {
    font-size: 14px;
    color: #9299b8;
    padding-left: 15px;
}

html[dir='rtl'] .sDash_upload-basic .sDash_upload-text {
    padding-left: 0px;
    padding-right: 15px;
}

.sDash_upload-basic .sDash_upload-browse {
    font-size: 14px;
    color: #9299b8;
    display: inline-block;
    padding: 14px 23px;
    border-left: 1px solid #e3e6ef;
}

html[dir='rtl'] .sDash_upload-basic .sDash_upload-browse {
    border-left: 0 none;
    border-right: 1px solid #e3e6ef;
}

.ant-card.sDash_upload-form .ant-card-body {
    padding-bottom: 15px !important;
}

/* Ant Picker */

.ant-picker {
    border-color: #e3e6ef !important;
}

/* Ant Dropdown */

.ant-dropdown {
    box-shadow: 0 5px 20px #9299b820;
    background: #fff;
    border-radius: 6px;
    padding: 15px 0 !important;
    border: 0 none;
}

.ant-dropdown a {
    display: flex;
    align-items: center;
    padding: 8px 24px;
    font-weight: 400;
    color: #5a5f7d;
}

.ant-dropdown a i,
.ant-dropdown a svg,
.ant-dropdown a img {
    margin-right: 8px;
}

.ant-dropdown div {
    box-shadow: 0 0;
    border-radius: 5px;
}

/* Picker Under Input */

.ant-form-item-control-input .ant-picker {
    padding: 0 12px 0 0;
}

/* Leaflet COntainer */

.leaflet-container {
    z-index: 0;
}

/* Table Bordered*/

.table-bordered .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f8f9fb !important;
}

.table-bordered .ant-table-thead > tr > th {
    background: #fff;
    border-top: 1px solid #f1f2f6;
}

.table-bordered .ant-table-tbody > tr > td {
    border-color: #f1f2f6 !important;
}

.table-bordered .ant-table-thead tr th,
.table-bordered .ant-table-tbody tr td {
    padding: 16px 25px;
}

.table-bordered .ant-table-thead tr th:last-child,
.table-bordered .ant-table-tbody tr td:last-child {
    text-align: right;
}

/* Full Width Table */

.full-width-table .ant-card-body {
    padding: 0 !important;
}

.full-width-table .ant-table {
    border-radius: 10px !important;
}

.full-width-table .top-seller-table {
    min-height: 406px;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell {
    white-space: normal;
    padding: 16px 15px;
}

.full-width-table
    .top-seller-table
    .ant-table-content
    .ant-table-cell:first-child {
    padding-left: 25px;
}

.full-width-table
    .top-seller-table
    .ant-table-content
    .ant-table-cell:last-child {
    padding-right: 25px;
}

.full-width-table .top-seller-table th {
    text-align: right;
    color: #272b41;
    font-weight: 500;
}

.full-width-table .top-seller-table td {
    color: #5a5f7d;
}

.full-width-table .top-seller-table .ant-table-tbody > tr > td {
    text-align: right;
}

.full-width-table .top-seller-table th:first-child,
.full-width-table .top-seller-table td:first-child {
    text-align: left !important;
}

/* Table Responsive */

.table-responsive .ant-table-content {
    display: block;
    width: 100%;
    overflow-x: auto;
}

.table-responsive .ant-table-content .ant-table-cell {
    white-space: nowrap;
}

/* Rich TextEditor  */

.RichTextEditor__root___2QXK- {
    border: 0 none !important;
}

.RichTextEditor__root___2QXK- .EditorToolbar__root___3_Aqz {
    margin: 0;
    border-color: #f1f2f6;
}

.RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button {
    padding: 0;
    border: 0 none;
    background: #fff;
    margin-right: 8px;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx .Dropdown__value___34Py9 {
    border: 0 none;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx select {
    border-right-width: 0px;
}

.RichTextEditor__editor___1QqIU .DraftEditor-editorContainer {
    border: 0 none;
}

/* ChatBox Dropdwon */

.atbd-chatbox__messageControl {
    min-width: 210px;
}

.atbd-chatbox__messageControl ul li a {
    padding: 4px 24px;
}

.atbd-chatbox__emoji {
    margin: -4.48px 0;
    padding: 0 10px;
}

.atbd-chatbox__emoji ul {
    display: flex;
    align-items: center;
}

.atbd-chatbox__emoji ul li {
    display: inline-block;
}

.atbd-chatbox__emoji ul li a {
    display: block;
    font-size: 20px;
    padding: 4px 7px;
    background: #fff;
}

.atbd-chatbox__emoji ul li a:hover {
    background-color: transparent;
}

.atbd-chatbox__emoji ul li a svg {
    margin: 0;
}

.rdrMonths {
    flex-wrap: wrap;
}

.ant-space {
    flex-wrap: wrap;
}

.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
    display: flex;
    align-items: center;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-item {
    display: inline-flex;
}

.ant-menu-submenu-popup {
    z-index: 105;
}

.ant-menu-submenu-popup .ant-menu-sub {
    padding: 8px 0;
}

.ant-menu-submenu-popup
    .ant-menu-submenu.ant-menu-submenu-inline
    .ant-menu-submenu-title,
.ant-menu-submenu-popup .ant-menu-item {
    display: block;
}

.ant-menu-submenu-popup .ant-menu-item {
    margin-bottom: 0 !important;
}

.ant-menu-sub.ant-menu-vertical {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.ant-menu-sub.ant-menu-vertical .ant-menu-item a {
    color: #5a5f7d;
}

/* page heading */

.ant-page-header {
    padding: 26px 30px 26px 30px !important;
}

.ant-page-header.header-boxed {
    padding: 26px 180px 26px 180px !important;
}

.ant-page-header-heading {
    align-items: center;
}

.ant-page-header-heading-title {
    text-transform: capitalize;
}

/* Antd drag & drop */

.row-dragging {
    /* background: #fafafa; */
    /* border: 1px solid #ccc; */
    box-shadow: 0 15px 50px #9299b820;
    display: flex;
}

.row-dragging tr {
    box-shadow: 0 15px 50px #9299b820;
}

.row-dragging td {
    padding: 16px;
    color: #000;
    position: relative;
    z-index: 9999;
    opacity: 0.5;
    vertical-align: middle;
}

.row-dragging td .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #20c997;
    border-color: #20c997;
}

.row-dragging td .todos-action,
.row-dragging td .table-actions {
    display: none;
}

.row-dragging td .user-info figcaption {
    display: none;
}

.row-dragging td .feather-move,
.row-dragging td .drag_email,
.row-dragging td .drag_company,
.row-dragging td .drag_designation,
.row-dragging td .drag_join-date,
.row-dragging td .active {
    display: inline-block;
    margin-top: 10px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

/* File Export Import Modal */
.sDash_export-wrap .ant-modal {
    width: 390px !important;
}

.sDash_export-wrap .ant-modal .ant-modal-header {
    border-bottom: 0 none;
    padding: 18px 30px 0 30px;
}

.sDash_export-wrap .ant-modal .ant-modal-body {
    padding: 25px 30px 30px 30px;
}

.sDash_export-wrap .ant-modal .sDash-button-grp {
    text-align: right;
}

.sDash_export-wrap .ant-modal .sDash-button-grp {
    margin: 20px -8px -8px -8px;
}

.sDash_export-wrap .ant-modal .sDash-button-grp button {
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    height: 40px;
    padding: 0 16px;
    margin: 8px;
}

.sDash_export-wrap .ant-modal .sDash-button-grp button.ant-btn-white {
    color: #9299b8 !important;
}

.sDash_export-wrap .ant-form-item-control-input {
    min-height: 36px;
}

.sDash_export-wrap .ant-form-item-control-input input {
    font-size: 14px;
    font-weight: 400;
    padding: 6px 20px;
    border-radius: 5px;
    color: #9299b8;
}

.sDash_export-wrap .ant-select-single {
    width: 100% !important;
}

.sDash_export-wrap .ant-form .ant-form-item {
    margin-bottom: 15px;
}

.sDash_export-wrap .ant-select-single .ant-select-selector {
    padding: 0 20px;
    border-color: #e3e6ef !important;
}

.sDash_create-file .sDash-button-grp {
    text-align: right;
}

.sDash_create-file .ant-modal {
    width: 390px !important;
}

.sDash_create-file .ant-modal .ant-form-item {
    margin-bottom: 20px;
}

.sDash_create-file .ant-modal-header {
    border-bottom: 0 none;
    padding-bottom: 6px;
}

.sDash_create-file .sDash-button-grp button {
    height: 40px;
    border-radius: 5px;
    margin: 5px;
}

.sDash_create-file .ant-form-item-control-input {
    border-radius: 5px;
}

.sDash_create-file .ant-form-item-control-input input {
    border: 1px solid #e3e6ef;
}

/* Task Modal */
.sDash_task-details .ant-modal-content .ant-modal-close {
    top: 10px;
}

.sDash_task-details .ant-modal-header {
    border-bottom: 0 none;
    padding: 30px 30px 0;
}

.sDash_task-details .ant-modal {
    width: 600px !important;
}

.sDash_task-details .ant-modal-header .ant-modal-title h4 {
    font-size: 20px;
    font-weight: 500;
    color: #272b41;
    margin-bottom: 4px;
}

.sDash_task-details .ant-modal-header .ant-modal-title .sub-text {
    font-size: 14px;
    font-weight: 400;
    color: #868eae;
}

.sDash_task-details .sDash_task-details-modal .sDash_task-details__label {
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.sDash_task-details
    .sDash_task-details-modal
    .sDash_task-details-modal__description
    textarea {
    padding: 10px 20px;
    min-height: 88px;
    width: 100%;
    border: 0 none;
    border-radius: 4px;
    background-color: #f4f5f7;
    resize: none;
}

.sDash_task-details
    .sDash_task-details-modal
    .sDash_task-details-modal__description
    textarea:focus {
    outline: none;
}

.sDash_task-details
    .sDash_task-details-modal
    .sDash_task-details-modal__description
    textarea::placeholder {
    color: #5a5f7d;
    font-size: 15px;
}

.sDash_task-details .ant-modal-body {
    padding: 14px 30px 30px 30px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block {
    margin-top: 26px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-wrap {
    position: relative;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form {
    position: absolute;
    width: 240px;
    padding: 18px;
    left: 0;
    top: 50px;
    box-shadow: 0 15px 30px #9299bb30;
    background-color: #fff;
    border: 1px solid #e3e6ef;
    border-radius: 6px;
    z-index: 222;
}

html[dir='rtl']
    .sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form {
    left: auto;
    right: 0;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form
    .add-checklist {
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    width: 100%;
    height: 38px;
    border: 1px solid #e3e6ef;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form
    .addChecklist-form-action {
    margin-top: 15px;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form
    .addChecklist-form-action
    a {
    position: relative;
    top: 3px;
    display: inline-flex;
    align-items: center;
    line-height: 1;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form
    .addChecklist-form-action
    a
    svg {
    color: #9299bb;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form
    .addChecklist-form-action
    .btn-add {
    margin-right: 15px;
}

html[dir='rtl']
    .sDash_task-details
    .ant-modal-body
    .sDash_checklist-block
    .addChecklist-form
    .addChecklist-form-action
    .btn-add {
    margin-left: 15px;
    margin-right: 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block button {
    height: 38px;
    padding: 0px 18.37px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row {
    margin-top: 26px;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist-item:not(:last-child) {
    margin-bottom: 30px;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist-item__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist-item__top
    button {
    padding: 0px 16.32px;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist-item__title {
    font-size: 16px;
    font-weight: 500;
    color: #272b41;
    margin-bottom: 0;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist__progress
    .ant-progress-inner
    .ant-progress-bg {
    height: 5px !important;
    background-color: #20c997;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist__progress
    .ant-progress
    .ant-progress-text {
    font-size: 12px;
    font-weight: 500;
    order: -1;
    margin: 0 10px 0 0;
}

html[dir='rtl']
    .sDash_task-details
    .ant-modal-body
    .sDash_checklist-row
    .sDash_checklist__progress
    .ant-progress
    .ant-progress-text {
    margin: 0 0 0 10px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-tasks {
    margin: 5px 0 5px 0;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-tasks
    .ant-checkbox-wrapper
    .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border-color: #c6d0dc;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-tasks
    .ant-checkbox-wrapper
    .ant-checkbox-checked
    .ant-checkbox-inner {
    background-color: #20c997;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-tasks
    .ant-checkbox-wrapper
    .ant-checkbox-checked:after {
    border-color: #20c997;
}

.sDash_task-details
    .ant-modal-body
    .sDash_checklist-item
    .sDash_checklist-tasks-wrap
    button {
    padding: 0px 35px;
}

.sDash_task-details .ant-modal-body {
    max-height: 800px;
    overflow-y: auto;
}

@media (max-width: 767px) {
    .ant-page-header {
        padding: 26px 15px 26px 15px !important;
    }
}

.page-header-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: -6px -4px;
}

.page-header-actions button {
    font-size: 12px;
    font-weight: 500;
    height: 34px;
    padding: 0 12.32px;
    box-shadow: 0 3px 5px #9299b805;
    margin: 6px 4px;
}

.page-header-actions button.ant-btn-white:focus {
    background-color: #fff !important;
}

.page-header-actions button + button {
    margin-left: 8px;
}

.page-header-actions button.ant-btn-white svg {
    width: 12px;
    height: 12px;
    margin-right: 2px;
    color: #ffffff;
}

/* Layout Css */

.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
    background: #272b41 !important;
}

.ant-menu-dark .ant-menu-item a {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark .ant-menu-item a:hover {
    color: #ffffff;
}

.ant-menu-dark .ant-menu-submenu span {
    color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.05);
}

.ant-menu-inline-collapsed-tooltip a {
    color: #000 !important;
}

.ant-menu.ant-menu-dark
    .ant-menu-submenu-title
    .ant-menu-submenu-arrow::before {
    background: transparent;
}

/* Chart Label */

.chart-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    color: #5a5f7d;
}

.chart-label .label-dot {
    margin-right: 8px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

.chart-label .label-dot.dot-success {
    background: #20c997;
}

.chart-label .label-dot.dot-info {
    background: #ffffff;
}

.chart-label .label-dot.dot-warning {
    background: #fa8b0c;
}

/* NOtification CSS */

.ant-notification {
    z-index: 99999 !important;
}

.rdrInputRange {
    padding-left: 10px !important;
}

/* Overlay Dark */
.overlay-dark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #10122130;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
}

.overlay-dark.show {
    z-index: 999;
    opacity: 1;
    visibility: visible;
}

.ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
}

/* Responsive CSS */

@media only screen and (max-width: 1599px) {
    .ant-page-header.header-boxed {
        padding: 26px 130px 26px 130px !important;
    }
}

@media only screen and (max-width: 1500px) {
    .full-width-table .revenue-table {
        min-height: 100%;
    }

    .pop-confirm .ant-btn {
        padding: 0 20px;
        max-width: 60px;
    }

    .pop-confirm.pop-confirm-right {
        margin-left: 300px !important;
    }

    .pop-confirm.pop-confirm-bottom,
    .pop-confirm.pop-confirm-top {
        margin-left: 80px !important;
    }
}

@media only screen and (max-width: 1399px) {
    .ant-page-header.header-boxed {
        padding: 26px 50px 26px 50px !important;
    }
}

@media only screen and (max-width: 1199px) {
    .ant-page-header {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 991px) {
    .ant-page-header.header-boxed {
        padding: 26px 30px 26px 30px !important;
    }

    .rdrMonths .rdrMonth {
        width: 100%;
        margin-bottom: 30px;
    }

    .rdrDateRangePickerWrapper.PreviewArea {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rdrCalendarWrapper.rdrDateRangeWrapper {
        margin-top: 20px;
    }

    .ant-menu-inline-collapsed-tooltip {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .ant-page-header-heading-left {
        max-width: 320px;
    }
}

@media only screen and (max-width: 767px) {
    .ant-page-header {
        padding: 26px 15px 26px 15px !important;
    }

    .ant-page-header-heading {
        flex-flow: column;
        align-items: center;
        justify-content: center !important;
    }

    .ant-page-header-heading-left {
        max-width: 100%;
    }

    .ant-page-header-heading .ant-page-header-heading-title {
        margin-right: 0;
        white-space: normal;
        text-align: center;
    }

    .ant-page-header-heading-extra {
        white-space: normal !important;
        margin: 12px 0 4px !important;
    }

    .ant-page-header-heading-extra .page-header-actions {
        white-space: normal !important;
        text-align: center;
    }

    .ant-card-body {
        padding: 20px !important;
    }

    .ant-card-head {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .ant-pagination .ant-pagination-item,
    .ant-pagination .ant-pagination-item.ant-pagination-prev,
    .ant-pagination .ant-pagination-item.ant-pagination-next,
    .ant-pagination .ant-pagination-jump-prev {
        margin-right: 5px;
    }

    .ant-pagination li.ant-pagination-item,
    .ant-pagination li.ant-pagination-prev,
    .ant-pagination li.ant-pagination-next {
        height: 25px;
        min-width: 25px;
        line-height: 22px;
    }

    .ant-pagination li.ant-pagination-prev .anticon,
    .ant-pagination li.ant-pagination-next .anticon {
        vertical-align: 0.15em;
    }

    .ant-table-pagination {
        float: none !important;
        text-align: center;
    }

    .ant-table-pagination li.ant-pagination-total-text {
        display: block;
        margin-bottom: 8px;
    }

    .ant-table-pagination li {
        margin-right: 8px !important;
    }

    .ant-pagination
        .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
        height: 25px !important;
    }

    .ant-pagination
        .ant-select-single
        .ant-select-selector
        .ant-select-selection-item {
        line-height: 23px !important;
    }

    .rdrDefinedRangesWrapper {
        border-right: 0 none;
    }
}

@media only screen and (max-width: 575px) {
    .btn-inc,
    .btn-dec {
        height: 30px;
        width: 30px;
    }

    .ant-page-header {
        padding: 16px 20px !important;
    }

    .ant-layout-header {
        height: auto;
    }

    .ant-card-head {
        line-height: 1;
    }

    .ant-card-head-title {
        white-space: normal !important;
    }

    .pop-confirm .ant-btn {
        max-width: 60px;
        padding: 0 20px;
    }

    .pop-confirm.pop-confirm-right {
        margin-left: 320px !important;
    }

    .pop-confirm.pop-confirm-bottom {
        margin-left: 95px !important;
    }

    /* Card Heading */
    .ant-card-head-wrapper {
        flex-flow: column;
        align-items: center;
    }

    .ant-card-head-wrapper .ant-card-extra {
        padding: 10px 0 16px !important;
        float: none;
        margin: 0;
        flex-flow: column;
    }

    .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger + .card-nav {
        margin-bottom: 8px;
    }

    .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger {
        margin: 0 !important;
    }

    .ant-card-head-wrapper .ant-card-head-title {
        padding: 20px 0 0px !important;
    }

    .card-nav ul li.active:before {
        display: none;
    }

    .ant-card-head-title > div {
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    .ant-card-head-title > div span {
        margin: 8px 0 0;
    }
}

.bmzxig .ant-table tr th:first-child,
.bmzxig .ant-table tr td:first-child {
    padding-right: 15px;
}

/* Emprt */

.ant-empty .ant-empty-footer button {
    padding: 0px 15.58px !important;
}

/* Add Event Modal */
.addEvent-modal .ant-modal-header {
    padding: 20px 25px;
}

.addEvent-modal .ant-modal-header .ant-modal-close-x svg {
    color: #5a5f7d;
}

.addEvent-modal .ant-modal-header .ant-modal-title {
    font-size: 15px;
    font-weight: 500;
    color: #272b41;
}

.addEvent-modal .ant-modal-body {
    padding: 23px 25px;
}

/* Event Dropdown */

.event-dropdown {
    min-width: auto !important;
    max-width: 450px;
    padding: 0 !important;
    margin: 6px 0 0 !important;
    box-shadow: 0 10px 40px #9299b820 !important;
}

.event-dropdown div {
    border-radius: 8px;
}

.event-dropdown .ant-card {
    width: 100% !important;
    margin-bottom: 0 !important;
}

@media only screen and (max-width: 479px) {
    .placement-confirm {
        display: flex;
        flex-wrap: wrap;
        margin: -5px -10px 0 -5px;
    }

    .placement-confirm .pop-confirm {
        flex: 0 0 50%;
    }

    .pop-confirm .ant-btn {
        display: block;
        padding: 0 20px;
        max-width: 80px;
        min-width: 80px;
        margin: 10px;
    }

    .pop-confirm.pop-confirm-right {
        margin-left: 0px !important;
    }

    .pop-confirm.pop-confirm-top {
        margin-left: 0px !important;
    }

    .pop-confirm.pop-confirm-bottom {
        margin-left: 0px !important;
        margin-top: 0px !important;
    }
}

@media only screen and (max-width: 400px) {
    .ant-select {
        width: 100% !important;
    }

    .rdrDefinedRangesWrapper {
        width: 100% !important;
    }

    .rdrDateRangePickerWrapper {
        flex-wrap: wrap;
    }

    .atbd-top-dropdwon {
        width: 280px;
        min-width: 180px;
    }

    .atbd-top-dropdwon .atbd-top-dropdwon__title {
        min-height: 40px;
    }

    .atbd-top-dropdwon .atbd-top-dropdwon__nav li:not(:last-child) {
        margin-bottom: 10px;
    }

    .atbd-top-dropdwon .atbd-top-dropdwon__nav li a {
        padding: 10px 0px;
    }

    .atbd-top-dropdwon .atbd-top-dropdwon__content img {
        margin-right: 15px;
    }

    .atbd-top-dropdwon .atbd-top-dropdwon__content figcaption {
        margin-left: 0;
    }

    .atbd-top-dropdwon
        .atbd-top-dropdwon__content
        figcaption
        .atbd-top-dropdwonText {
        min-width: 155px;
    }

    .ant-drawer-content-wrapper {
        width: 260px !important;
    }

    .rdrCalendarWrapper.rdrDateRangeWrapper {
        margin-top: 0;
    }
}

@media only screen and (max-width: 379px) {
    .ant-card-head-wrapper .ant-card-extra .ant-radio-button-wrapper {
        height: 32px !important;
        line-height: 30px !important;
    }

    .ant-notification-notice {
        width: 275px;
    }
}

@media (max-width: 991px) {
    .mail-sideabr {
        box-shadow: 0 0 10px #00000020;
    }
}

/* Invoice Print Style */
@media print {
    .invoice-area .ant-page-header {
        display: none;
    }

    .customizer-trigger {
        display: none;
    }
}

/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff !important;
} */

/* custom label */

/* card spin loader */

.ant-card-body .sd-spin div,
.ant-card-body .spin div {
    position: relative;
}

.ant-card-body .sd-spin,
.ant-card-body .spin {
    height: 200px;
}

.ant-card-body {
    position: relative;
}

.ant-card-body .sd-spin .ant-spin,
.ant-card-body .spin .ant-spin {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 200px;
}

.starActive svg {
    color: red;
}

/* vectormap zoom in / zoom out */

.jvectormap-zoomin,
.jvectormap-zoomout {
    width: 27px;
    height: 27px;
    background: none;
    color: #5a5f7d;
    border: 1px solid #f1f2f6;
    padding: 0;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: auto;
    right: 0;
    top: auto;
    background-color: #fff;
}

.jvectormap-zoomin {
    border-radius: 6px 6px 0 0;
    bottom: 36px;
}

.jvectormap-zoomout {
    border-radius: 0 0 6px 6px;
    bottom: 10px;
}

.jvectormap-tip {
    padding: 7px 12px;
    border: 0 none;
    font-size: 12px;
    background: #272b41;
}

.btn-rtl {
    width: 60px;
    height: 60px;
    border: 1px solid #fff;
    background: #4347d9;
    color: #fff;
    position: fixed;
    right: 0;
    top: 50%;
    margin-top: -30px;
    z-index: 99999999;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* masonry-grid start from here */

.my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px;
    /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 10px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
}

.common-ul {
    padding: 5px 0;
    padding-left: 10px;
}

.display {
    display: none;
}

li.active > .display {
    display: block;
}

/* rtl section from here */
html[dir='rtl'] .ant-card-head-title span {
    margin-right: 15px;
}

html[dir='rtl'] span[aria-label='arrow-right'] svg,
html[dir='rtl'] span[aria-label='arrow-left'] svg {
    transform: rotateY(180deg);
}

html[dir='rtl'] .ant-menu.ant-menu-vertical {
    text-align: right !important;
}

html[dir='rtl']
    .ant-menu.ant-menu-vertical
    .ant-menu-submenu-placement-rightTop {
    left: -170px !important;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-vertical-left {
    text-align: right;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-submenu-arrow {
    right: auto;
    left: 16px;
    transform: rotateY(180deg);
}

html[dir='rtl'] .ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 15px !important;
}

:root {
    --blue: #0aa9e1;
    --green: #0ae1a0;
    --black: #000000;
    --gold: #fed7aa;
    --gold-trans: #fdf9f4;
    --red: #eb5757;
    --transparant: transparant;
    --c-0f151d: #0f151d;
    --c-334155: #334155;
    --c-d7e2f2: #d7e2f2;
    --c-f3f8fe: #f3f8fe;
    --c-99a3b8: #99a3b8;
    --c-ffffff66: #ffffff66;
    --c-fff33: #ffffff33;
    --c-667085: #667085;
    --c-e1e6ef: #e1e6ef;
    --c-344255: #344255;
    --c-101828: #101828;
    --c-331a: #3341551a;
    --c-e8e8e8: #e8e8e8;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poly&display=swap');

.poppins {
    font-family: 'Poppins', sans-serif;
}

.poly {
    font-family: 'Poly', serif;
}

.landing-page-body {
    overflow-x: hidden !important;
}

.cp {
    cursor: pointer;
}

@media only screen and (min-width: 320px) {
    html {
        font-size: 12px;
    }

    .f-100 {
        font-size: 3.25rem !important;
    }

    .btn-create-acc,
    .btn-create-acc:hover {
        padding: 11px 145px !important;
    }

    .f-mobile-16 {
        font-size: 16px !important;
    }

    .max-w-sm-250 {
        max-width: 250px !important;
    }

    .w-sm-100 {
        width: 100% !important;
    }
}

@media only screen and (min-width: 768px) {
    .max-w-sm-250 {
        max-width: 100% !important;
    }

    .w-md-auto {
        width: auto !important;
    }

    .px-md-16 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .px-md-12 {
        padding-right: 0.75rem !important;
        padding-left: 0.75rem !important;
    }

    .pl-md-120 {
        padding-left: 7.5rem !important;
    }

    .px-2.pr-md-40,
    .pr-md-40 {
        padding-right: 2.5rem !important;
    }

    .px-md-80 {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }

    .pl-125 {
        padding-left: 7.8125rem !important;
    }

    .mx-md-24 {
        margin-right: 24px !important;
        margin-left: 24px !important;
    }

    .max-w-md-255 {
        max-width: 15.9375rem !important;
    }

    .max-w-md-235 {
        max-width: 14.6875rem !important;
    }

    .max-w-md-470 {
        max-width: 29.375rem !important;
    }

    .max-w-md-976 {
        max-width: 61rem !important;
    }

    .py-md-60 {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }
}

@media only screen and (min-width: 960px) {
    html {
        font-size: 14px;
    }

    .px-md-24 {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .pr-md-24 {
        padding-right: 24px !important;
    }

    .pl-md-24 {
        padding-left: 24px !important;
    }

    .max-w-md-605 {
        max-width: 37.8125rem !important;
    }
}

@media only screen and (min-width: 1240px) {
    html {
        font-size: 16px;
    }

    .w-md-934 {
        width: 58.375rem !important;
    }

    .w-md-150px {
        width: 150px !important;
        box-sizing: content-box;
    }

    .h-md-572 {
        height: 35.75rem !important;
    }

    .gap-md-261 {
        gap: 16.3125rem;
    }

    .bg-md-ff {
        background: var(--bs-white);
    }

    .br-top-bottom-left-md-50 {
        border-radius: 50px 0px 0px 50px !important;
    }

    .border-radius-md-0 {
        border-radius: 0 !important;
    }

    /* Padding MD Start*/

    .px-md-120 {
        padding-left: 7.5rem !important;
        padding-right: 7.5rem !important;
    }

    .pt-md-11 {
        padding-top: 0.6875rem !important;
    }

    .pt-md-70 {
        padding-top: 4.375rem !important;
    }

    .pt-md-120 {
        padding-top: 7.5rem !important;
    }

    .pl-md-133 {
        padding-left: 8.3125rem !important;
    }

    .pl-md-10 {
        padding-left: 10px;
    }

    .pr-md-10 {
        padding-right: 10px !important;
    }

    .pr-md-120 {
        padding-right: 7.5rem !important;
    }

    .pr-md-7-5 {
        padding-right: 0.4688rem !important;
    }

    .pl-md-7-5 {
        padding-left: 0.4688rem !important;
    }

    .pb-md-200 {
        padding-bottom: 12.5rem !important;
    }

    .py-md-29 {
        padding-top: 1.8125rem !important;
        padding-bottom: 1.8125rem !important;
    }

    .pr-md-32 {
        padding-right: 2rem !important;
    }

    .pr-md-34 {
        padding-right: 2.125rem !important;
    }

    .pr-md-61 {
        padding-right: 3.8125rem !important;
    }

    .pl-md-35 {
        padding-left: 2.1875rem !important;
    }

    .py-md-11 {
        padding-top: 0.6875rem !important;
        padding-bottom: 0.6875rem !important;
    }

    .px-md-20 {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
    }

    .py-md-24 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .pr-md-57 {
        padding-right: 3.5625rem !important;
    }

    .pr-md-77 {
        padding-right: 4.8125rem !important;
    }

    .pt-md-12 {
        padding-top: 0.75rem !important;
    }

    .pb-md-110 {
        padding-bottom: 6.875rem !important;
    }

    .px-md-45 {
        padding-left: 2.8125rem !important;
        padding-right: 2.8125rem !important;
    }

    .pb-md-30 {
        padding-bottom: 1.875rem !important;
    }

    .pt-md-10 {
        padding-top: 0.625rem !important;
    }

    .pb-md-20 {
        padding-bottom: 1.25rem !important;
    }

    .py-md-80 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .pt-md-100 {
        padding-top: 6.25rem !important;
    }

    .py-md-100 {
        padding-top: 6.25rem !important;
        padding-bottom: 6.25rem !important;
    }

    .pb-md-100 {
        padding-bottom: 6.25rem !important;
    }

    .pb-md-40 {
        padding-bottom: 2.5rem !important;
    }

    .pb-md-60 {
        padding-bottom: 3.75rem !important;
    }

    .pl-md-30 {
        padding-left: 1.875rem !important;
    }

    .pl-md-44 {
        padding-left: 2.75rem !important;
    }

    .pb-md-43 {
        padding-bottom: 2.6875rem !important;
    }

    .pr-md-16 {
        padding-right: 1rem !important;
    }

    .pt-md-50 {
        padding-top: 50px !important;
    }

    /* Padding MD End */

    /* Margin MD Start */

    .mr-md-40 {
        margin-right: 2.5rem !important;
    }

    .ml-md-8 {
        margin-left: 0.5rem !important;
    }

    .mb-md-24 {
        margin-bottom: 1.5rem !important;
    }

    .mb-md-50 {
        margin-bottom: 3.125rem !important;
    }

    .mt-md--46 {
        margin-top: -2.875rem !important;
    }

    .mb-md--20 {
        margin-bottom: -20rem;
    }

    .mb-md-82 {
        margin-bottom: 5.125rem;
    }

    /* Margin MD End */

    .customer-table {
        margin-top: 30px;
        margin-bottom: 24px;
        padding: 60px 40px;
        border: 1px solid var(--c-e1e6ef);
        border-radius: 20px;
    }
}

@media only screen and (min-width: 1400px) {
    .pl-60px {
        padding-left: 3.75rem !important;
    }

    .px-lg-29 {
        padding-left: 1.8125rem !important;
        padding-right: 1.8125rem !important;
    }

    .w-732px {
        width: 732px !important;
    }

    .max-w-881px {
        max-width: 881px !important;
    }

    .max-w-1159px {
        max-width: 1159px !important;
    }

    .max-w-1385px {
        max-width: 1385px !important;
    }

    .max-w-1504px {
        max-width: 1504px !important;
    }

    .max-w-1684px {
        max-width: 1684px !important;
    }

    .max-w-859 {
        max-width: 53.6875rem !important;
    }

    .max-w-847 {
        max-width: 52.9375rem !important;
    }

    .max-w-836 {
        max-width: 52.25rem !important;
    }

    .max-w-974 {
        max-width: 60.875rem !important;
    }

    .max-w-1023 {
        max-width: 63.9375rem !important;
    }

    .max-w-305 {
        max-width: 19.0625rem !important;
    }

    .max-w-327 {
        max-width: 20.4375rem !important;
    }

    .max-w-350 {
        max-width: 21.875rem !important;
    }

    .min-w-473 {
        min-width: 29.5625rem !important;
    }

    .min-w-369 {
        min-width: 23.0625rem !important;
    }

    .min-max-w-455 {
        min-width: 28.4375rem !important;
        max-width: 28.4375rem !important;
    }
}

/* font-size Start */

.f-13 {
    font-size: 13px;
}

.f-14 {
    font-size: 0.875rem;
}

.f-14px {
    font-size: 14px;
}

.f-16 {
    font-size: 1rem;
}

.f-17 {
    font-size: 17px;
}

.f-18 {
    font-size: 1.125rem;
}

.f-20 {
    font-size: 1.25rem;
}

.f-22 {
    font-size: 1.375rem;
}

.f-24 {
    font-size: 1.5rem;
}

.f-25 {
    font-size: 1.5625rem;
}

.f-28 {
    font-size: 28px;
}

.f-30 {
    font-size: 1.875rem;
}

.f-36 {
    font-size: 2.25rem;
}

.f-42 {
    font-size: 2.625rem;
}

.f-50 {
    font-size: 3.125rem;
}

.f-60 {
    font-size: 3.75rem;
}

.f-70 {
    font-size: 4.375rem;
}

.f-100 {
    font-size: 6.25rem;
}

/* font-size End */

.lh-0 {
    line-height: 0;
}

.lh-normal {
    line-height: normal;
}

.lh-16 {
    line-height: 1rem;
}

.lh-17 {
    line-height: 1.0625rem;
}

.lh-20 {
    line-height: 1.25rem;
}

.lh-23 {
    line-height: 1.4375rem;
}

.lh-24 {
    line-height: 1.5rem;
}

.lh-26 {
    line-height: 1.625rem;
}

.lh-32 {
    line-height: 2rem;
}

.lh-36 {
    line-height: 2.25rem;
}

.lh-40 {
    line-height: 2.5rem;
}

.lh-48 {
    line-height: 3rem;
}

.lh-120 {
    line-height: 7.5rem;
}

/* Padding Start */

.pb-6 {
    padding-bottom: 0.375rem;
}

.pr-6 {
    padding-right: 0.375rem;
}

.px-6 {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.pl-6 {
    padding-left: 0.375rem !important;
}

.pl-7 {
    padding-left: 7px !important;
}

.pr-7 {
    padding-right: 7px !important;
}

.pr-11 {
    padding-right: 0.6875rem;
}

.pr-13 {
    padding-right: 0.8125rem;
}

.pr-16 {
    padding-right: 1rem;
}

.pr-12 {
    padding-right: 0.75rem !important;
}

.py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.pb-9 {
    padding-bottom: 0.5625rem;
}

.py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.pr-8 {
    padding-right: 0.5rem !important;
}

.pl-8 {
    padding-left: 0.5rem !important;
}

.pt-8 {
    padding-top: 0.5rem !important;
}

.pb-8 {
    padding-bottom: 0.5rem !important;
}

.pb-8px {
    padding-bottom: 8px !important;
}

.pl-20 {
    padding-left: 1.25rem;
}

.pr-57 {
    padding-right: 3.5625rem;
}

.pr-17 {
    padding-right: 1.0625rem;
}

.pr-20 {
    padding-right: 1.25rem;
}

.px-10px {
    padding-right: 10px;
    padding-left: 10px;
}

.px-11 {
    padding-right: 11px;
    padding-left: 11px;
}

.px-20 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.pr-77 {
    padding-right: 4.8125rem;
}

.pb-12 {
    padding-bottom: 0.75rem !important;
}

.pb-8 {
    padding-bottom: 0.5rem !important;
}

.pt-32 {
    padding-top: 2rem;
}

.pt-40 {
    padding-top: 2.5rem;
}

.pb-41 {
    padding-bottom: 2.5625rem;
}

.pb-22 {
    padding-bottom: 1.375rem;
}

.px-16 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.px-9 {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.pl-6 {
    padding-left: 0.375rem;
}

.p-15 {
    padding: 0.9375rem !important;
}

.p-20 {
    padding: 1.25rem !important;
}

.px-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
}

.py-4rem {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-5px {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.pr-4 {
    padding-right: 0.25rem !important;
}

.p-30 {
    padding: 1.875rem !important;
}

.p-40 {
    padding: 2.5rem !important;
}

.pb-14 {
    padding-bottom: 0.875rem;
}

.pb-15 {
    padding-bottom: 0.875rem;
}

.pb-16 {
    padding-bottom: 1rem;
}

.pb-32 {
    padding-bottom: 2rem;
}

.pb-35 {
    padding-bottom: 2.1875rem;
}

.pb-11 {
    padding-bottom: 0.6875rem;
}

.pb-18 {
    padding-bottom: 1.125rem;
}

.pb-25 {
    padding-bottom: 1.5625rem;
}

.pb-20.pb-6,
.pb-20.pb-0,
.pb-20 {
    padding-bottom: 1.25rem !important;
}

.pb-30 {
    padding-bottom: 1.875rem !important;
}

.pb-65 {
    padding-bottom: 4.0625rem;
}

.pr-87 {
    padding-right: 87px;
}

.px-48 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.pt-4px {
    padding-top: 4px !important;
}

.pl-16 {
    padding-left: 16px !important;
}

/* Padding End */

/* Margin Start */

.mb-44.mb-13,
.mb-44 {
    margin-bottom: 2.75rem;
}

.mx-20 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
}

.mb-24 {
    margin-bottom: 1.5rem;
}

.mb-16 {
    margin-bottom: 1rem;
}

.mb-8 {
    margin-bottom: 0.5rem;
}

.mb-14 {
    margin-bottom: 0.875rem;
}

.mb-20 {
    margin-bottom: 1.25rem;
}

.mb-82 {
    margin-bottom: 5.125rem;
}

/* Margin End */

/* height Start */

.h-2 {
    height: 2rem;
}

.h-8 {
    height: 0.5rem;
}

/* height End */

.box-sizing-content-box {
    box-sizing: content-box;
}

.text-danger {
    color: var(--red);
}

.input-filed,
.input-filed:focus {
    color: var(--c-0f151d);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-radius: 44px;
    border: 1px solid #e1e6ef;
    background: #fff;
    padding: 11px 26px;
    box-shadow: 0 0 0 0 !important;
}

.btn-country,
.btn-country:hover {
    color: var(--c-0f151d);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 11px 16px 11px 26px;
    border-radius: 44px;
    border: 1px solid #e1e6ef !important;
    background: #fff;
    width: -webkit-fill-available;
    text-align: start;
}

.c-101828 {
    color: var(--c-101828);
}

.border-y-0 {
    border-top: 0;
    border-bottom: 0;
}

.border-l-0 {
    border-left: 0;
}

.border-r-e1e6ef {
    border-right: 2px solid #e1e6ef;
}

.form-check .form-check-input {
    margin-top: 0;
}

.form-check-input,
.form-check-input:focus {
    border-radius: 8px !important;
    width: 24px;
    height: 24px;
    box-shadow: 0 0 0 transparent !important;
}

.form-check-input:checked {
    background-color: #334155;
    border-color: #334155;
}

.box-shadow-0 {
    box-shadow: 0 0 0 !important;
}

.btn-create-acc,
.btn-create-acc:hover {
    border-radius: 40px;
    color: var(--bs-white);
    background: var(--c-334155);
    padding: 11px 166px;
    width: 100%;
    font-size: 16px;
}

.country-dropdown-menu {
    margin-top: 10px !important;
    width: 327px;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    box-shadow: -2px 2px 12px 2px rgba(21, 30, 42, 0.08);
    border-radius: 16px;
    background: #fff;
}

.country-dropdown-input {
    margin: 0 16px;
    width: -webkit-fill-available;
    padding: 4px 16px;
    border: 1px solid #ccc;
    border-radius: 44px;
}

.country-name-dropdown-menu {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    box-shadow: -2px 2px 12px 2px rgba(21, 30, 42, 0.08);
    border-radius: 16px;
    background: #fff;
}

.success-badge {
    background-color: #4caf50;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
}

.error-badge {
    background-color: #ff0000;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
}

.btn-country-num,
.btn-country-num:hover {
    color: var(--c-0f151d) !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    padding: 0 !important;
    border: 0 !important;
    background: #fff !important;
    width: -webkit-fill-available !important;
    text-align: start !important;
}

.password-error-text {
    position: absolute;
    color: darkred;
    font-size: 12px;
    left: 27px;
}

.centered-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.l-0 {
    left: 0 !important;
}

.l-60 {
    left: 3.75rem;
}

.min-w-443 {
    min-width: 443px;
}

.check-box-custom {
    margin-top: 0 !important;
    margin-right: 10px !important;
}

.wh-102-97 {
    width: 102px;
    height: 97px;
}

.h-38vh {
    height: 38vh;
}

.ant-menu-item a::before {
    display: none;
}

.ant-layout-sider.ant-layout-sider-dark
    .ant-layout-sider-children
    .ant-menu
    .ant-menu-item.ant-menu-item-only-child {
    padding: 0 !important;
    justify-content: end;
    flex-wrap: wrap;
    align-items: flex-end;
    border-left: 3px solid transparent;
    margin-top: 0;
    margin-bottom: 16px;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 48px !important;
    line-height: 48px !important;
}

.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child,
.bAchwj
    .ant-layout-sider.ant-layout-sider-dark
    .ant-layout-sider-children
    .ant-menu
    .ant-menu-item.ant-menu-item-only-child:hover,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-image: linear-gradient(to right, #ffffff91, #d5d5d51e);
    background-color: transparent !important;
    border-radius: 0 !important;
    border-left: 3px solid #ffffff !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
    background: transparent !important;
}

.bAchwj
    .ant-layout-sider
    .ant-layout-sider-children
    .ant-menu
    .ant-menu-item
    a
    .feather {
    color: #ffffff !important;
}

.border-1-e1e6ef {
    border: 1px solid #e1e6ef !important;
}

.border-b-1-e1e6ef {
    border-bottom: 1px solid #e1e6ef;
}

.border-b-1-e8e8e8 {
    border-bottom: 1px solid var(--c-e8e8e8);
}

.br-10 {
    border-radius: 10px !important;
}

.br-11 {
    border-radius: 11px !important;
}

.br-12 {
    border-radius: 12px !important;
}

.br-24 {
    border-radius: 24px !important;
}

.br-44 {
    border-radius: 44px !important;
}

.ct {
    cursor: text;
}

.max-h-48 {
    max-height: 48px;
}

.lh-0 {
    line-height: 0;
}

.outline-none {
    outline: none;
}

.iVFNeT
    .ant-layout-sider
    .ant-layout-sider-children
    .ant-menu
    .ant-menu-item.ant-menu-item-selected
    svg,
.iVFNeT
    .ant-layout-sider
    .ant-layout-sider-children
    .ant-menu
    .ant-menu-item.ant-menu-item-selected
    i {
    color: #ffffff !important;
}

.ant-layout-sider
    .ant-layout-sider-children
    .ant-menu
    .ant-menu-item
    a
    .feather {
    width: 26px !important;
}

.label-search {
    border: 1px solid #e1e6ef;
    border-radius: 44px !important;
    padding: 11px 1.5625rem;
    cursor: text;
}

.about-search {
    border: 1px solid #e1e6ef;
    padding: 15px 26px 15px 26px;
    border-radius: 24px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    height: 142px;
}

.input-search {
    width: 100%;
}

.input-search,
.input-search:active,
.input-search:focus,
.input-search:focus-visible {
    border: 0;
    /* border-radius: 44px; */
    padding: 0 0 0 8px;
    outline: none;
}

.complete-step {
    background: #fed7aa;
    border-radius: 40px;
    padding: 4.5px 14px 4.5px 6px !important;
    cursor: pointer;
}

.mb-35 {
    margin-bottom: 35px;
}

.recent-review-card {
    height: 100%;
    padding: 24px 15px;
    border: 1px solid var(--c-e1e6ef);
    border-radius: 20px;
}

.h-404 {
    height: 404px;
}

.h-40 {
    height: 40px;
}

.wh-28 {
    width: 28px;
    height: 28px;
    overflow: hidden;
    border-radius: 50%;
}
.wh-28 img {
width: 100%;
height: 100%;
    object-fit: cover;
    object-position: center;
}

.text-gray {
    color: var(--c-667085);
}

.text-green {
    color: #09c05d;
}

.text-purple {
    color: #733ddb;
}

.max-h-317 {
    max-height: 19.8125rem;
}

.max-h-276 {
    max-height: 17.25rem;
}

.btn.btn-view-all {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    padding: 14px 0;
    color: var(--c-334155);
    border: 1px solid var(--c-334155);
    border-radius: 70px;
    line-height: 18px;
}

.btn.btn-view-all:hover .btn.btn-view-all:focus,
.btn.btn-view-all:focus-visible,
.btn.btn-view-all:active {
    color: var(--bs-white) !important;
    background: var(--c-334155) !important;
    border: 1px solid var(--c-334155) !important;
}

.vl {
    border-left: 1px solid #e0e0e0;
}

.br-top-left-22 {
    border-radius: 22px 0 0 22px;
}

.btn.btn-icon {
    font-size: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
    color: var(--c-667085);
    background: var(--bs-white);
    border: 1px solid var(--c-e1e6ef);
    border-radius: 24px;
}

.btn.btn-prim:hover,
.btn.btn-prim:focus,
.btn.btn-prim:focus-visible,
.btn.btn-prim:active,
.btn.btn-icon:hover,
.btn.btn-icon:focus,
.btn.btn-icon:focus-visible,
.btn.btn-icon:active {
    color: var(--bs-white) !important;
    background: var(--c-0f151d) !important;
    border: 1px solid var(--c-0f151d) !important;
}

.btn.btn-sec {
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--c-334155);
    background: #3341551a;
    border: 1px solid #3341551a;
    border-radius: 24px;
}

.btn.btn-sec:hover,
.btn.btn-sec:focus,
.btn.btn-sec:focus-visible,
.btn.btn-sec:active {
    color: var(--bs-white) !important;
    background: var(--bs-danger) !important;
    border: 1px solid var(--bs-danger) !important;
}

.btn.btn-prim {
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--bs-white);
    background: var(--c-334155);
    border: 1px solid var(--c-334155);
    border-radius: 24px;
}
.btn.add-btn-dark {
    color: var(--bs-white);
    background: var(--c-334155);
}
.btn.add-btn-dark:hover {
    color: var(--bs-white);
    background: var(--c-334155);
}

.ant-pagination-item a {
    border-radius: 7px;
}

.ant-pagination-item-active {
    background: var(--c-334155) !important;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    color: var(--bs-white) !important;
    background: var(--c-334155) !important;
}

.max-w-950 {
    max-width: 950px;
}

.max-w-605 {
    max-width: 605px;
}

.max-w-485 {
    max-width: 485px;
}

.text-area-des,
.text-area-des:focus,
.text-area-des:focus-visible,
.text-area-des:active {
    border: 1px solid #e1e6ef !important;
    border-radius: 20px;
    padding: 15px 26px !important;
    resize: none;
    outline: none;
}

.ant-select-selector {
    border-radius: 20px !important;
}

.bg-eaf1ff {
    background: #eaf1ff;
}

.h-100vh {
    height: 100vh;
}

.btn-date {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    color: var(--c-334155) !important;
    background: var(--bs-white) !important;
    border: 1px solid #33415533 !important;
    border-radius: 24px !important;
}

.btn-date.active,
.btn-date:hover {
    color: var(--bs-white) !important;
    background: var(--c-334155) !important;
    border: 1px solid var(--c-334155) !important;
}

.right-modal {
    display: block !important;
    position: fixed !important;
    background: #00000099 !important;
    z-index: 9999 !important;
    height: 100vh !important;
    width: 100% !important;
}

.right-modal-content {
    background: #ffffff;
    max-width: 375px;
    width: 100%;
    margin-left: auto;
}

.right-modal-header {
    padding: 27px 25px 23px;
    border-bottom: 1px solid var(--c-e1e6ef);
}

.right-modal-body {
    padding: 23px 25px 23px;
}

.right-modal-chart {
    background: #fed7aa;
    border-radius: 20px;
    padding: 17px 16px 16px 27px !important;
}

.right-sidebar {
    display: block !important;
    position: fixed !important;
    background: #00000099 !important;
    z-index: 9999 !important;
    height: 100vh !important;
    width: 100% !important;
}

.right-sidebar-content {
    background: #212529;
    width: 350px;
    height: auto;
    margin-left: auto;
}

.right-sidebar-header {
    padding: 48px 16px 32px;
}

.right-sidebar-body {
    padding: 0 16px;
}

.c-aa6f2b {
    color: #aa6f2b;
}

.check-box-card {
    padding: 20px;
    margin-top: 20px !important;
    margin-bottom: 30px;
    border: 1px solid var(--c-e1e6ef);
    border-radius: 24px;
}

.hl-radio {
    border-bottom: 1px solid var(--c-e1e6ef);
}

.radio-btn {
    border: 1px solid var(--c-e1e6ef);
    border-radius: 50%;
    height: 28px;
    width: 28px;
}

.radio-input:checked ~ .radio-label .radio-btn {
    background: var(--c-334155);
    border: 1px solid var(--c-334155);
    border-radius: 50%;
    height: 28px;
    width: 28px;
}

.radio-input:checked ~ .radio-label .radio-btn:after {
    content: '';
    position: relative;
    top: 7px;
    left: 7px;
    background: var(--c-334155);
    border: 1px solid var(--bs-white);
    border-top: 0;
    border-right: 0;
    height: 8px;
    width: 14px;
    display: block;
    transform: rotate(-42deg);
}

.h-168 {
    height: 168px;
}

.card-default {
    border: 1px solid var(--c-e1e6ef);
    border-radius: 24px;
}

.btn-try-now {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: var(--c-334155) !important;
    background: var(--bs-white) !important;
    border: 1px solid var(--c-e1e6ef) !important;
    border-radius: 24px !important;
    padding: 8px 10px 8px 14px !important;
}

.btn-try-now:hover {
    color: var(--bs-white) !important;
    background: var(--c-334155) !important;
    border: 1px solid var(--c-334155) !important;
}

.btn-try-now:hover svg path {
    fill: var(--bs-white) !important;
}

.h-84px {
    height: 84px;
}

.card-need-help {
    background: #fbeaea;
    border-radius: 24px;
    margin-top: 24px !important;
    padding: 17px 20px;
}

.c-e11919 {
    color: #e11919;
}

.app-none {
    appearance: none;
}

.tab-class {
    font-size: 16px;
    font-weight: 400;
    color: var(--c-667085);
    padding: 5px 14px;
    cursor: pointer;
}

.tab-class.active {
    background: #33415514;
    border-radius: 10px;
}

.focus:not(:focus-visible),
button:focus:not(:focus-visible) {
    outline: 0 !important;
    box-shadow: 0 0 0 0 transparent !important;
}

.h-76 {
    height: 76px !important;
}

.b-1-dddddd {
    border: 1px solid #dddddd;
}

.br-24 {
    border-radius: 24px;
}

.c-16ac7a {
    color: #16ac7a;
}

.bg-16ac7a1c {
    background: #16ac7a1c;
}

.br-7 {
    border-radius: 7px;
}

.min-h-100vh {
    min-height: 100vh;
}

.services-cont {
    min-height: 100vh;
    height: auto;
}

.price-card {
    padding: 56px 24px 40px;
    border-radius: 16px;
    border: 1px solid #dddddd;
    width: 24.8125rem;
    max-width: 24.8125rem;
}

.recommended {
    background: #fed7aa;
    border-radius: 24px;
    padding: 7.5px 10px !important;
    font-size: 16px;
    font-family: 'Poly', serif;
    color: var(--c-334155);
    cursor: pointer;
}

.border-b-1-ddd {
    border-bottom: 1px solid #dddddd;
}

.btn.btn-get-it-now {
    border-radius: 40px;
    color: var(--bs-white);
    background: var(--c-334155);
    border: 1px solid var(--c-334155);
    padding: 11px 0;
    width: 21.875rem;
    font-size: 16px;
}

.btn.btn-get-it-now:hover {
    color: var(--c-334155);
    background: var(--bs-white);
    border: 1px solid var(--c-334155);
}

.btn.btn-get-it-now-outline {
    border-radius: 40px;
    color: var(--c-334155);
    background: var(--bs-white);
    border: 1px solid var(--c-334155);
    padding: 11px 0;
    width: 21.875rem;
    font-size: 16px;
}

.btn.btn-get-it-now-outline:hover {
    color: var(--bs-white);
    background: var(--c-334155);
    border: 1px solid var(--c-334155);
}

.py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.br-16 {
    border-radius: 1rem !important;
}

.h-full {
    height: 100%;
}

.h-fit {
    height: fit-content;
}

.w-full {
    width: 100%;
}

.w-fit {
    width: fit-content;
}

.max-wh-160px {
    width: 160px;
    height: 160px;
}
@media (max-width: 1239px) {

    /* Tablet Mode */
    .max-wh-160px {
        max-width: 124px;
        height: 124px;
    }
}

@media (max-width: 768px) {

    /* Smaller Tablets */
    .max-wh-160px {
        width: 160px;
        height: 160px;
    }
}

.h-81vh {
    height: 81vh;
}

.max-h-25rem {
    max-height: 25.25rem;
}

.max-w-md-605 {
    max-width: 45rem;
}

.arrow-inner .react-calendar {
    margin: 0 !important;
}

.svg-auto svg {
    width: auto !important;
    height: auto !important;
}

.react-calendar__navigation {
    height: auto !important;
}

.active-badge {
    background: #09c05d1a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    height: 36px;
    margin: 0;
    border-radius: 12px;
    /* opacity: 0px; */
    width: 125px;
}

.active-badge > span {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: #09c05d;
}

.notification-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c2c2c2;
    position: relative;
}
.notification-counter {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 12px;
    height: 12px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
    border-radius: 50%;
}

.profile-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
}

