.dotpaymentmethod{
    width: 4px;
    height: 4px;
    background-color: #667085; 
    border-radius: 50%; 
    display: inline-block; 
    padding-left: 1px;
    margin-bottom: 2px;
  }
  .custom-bg-color {
    background-color: #334155;
    color: #FFFFFF;
}

.w-88 {
  width: 120px;
  cursor: pointer !important;
  font-size: 14px;
}

.select-card-bg {
  background-color: #e1e2e3;
}