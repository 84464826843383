.chat-list {
    position: relative;
    background: white;
    overflow-y: scroll;
    height: 800px;
}

.chat-list .arrow-rotate {
    transform: rotate(180deg);
}

.chat-list-toggle {
    width: fit-content;
}

.chat-card {
    padding: 12px 24px;
    width: 100%;
    cursor: pointer;
}

.chat-card.active {
    background-color: #f3f8fe;
}

.chat-card .heading {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #334155;
    margin: 0;
}

.chat-card .message {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #99a3b8;
    margin: 0;
}

.chat-card .time {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: #99a3b8;
    text-wrap: nowrap;
}

.chat-card .notification {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: white;
    background: #7f6df5;
    border-radius: 50%;
}

.togglebtn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50px;
    right: -15px;
    z-index: 999;
    padding: 6px 10px;
    border: 1px solid #e1e6ef;
    background: white;
    border-radius: 50px;
    cursor: pointer;
}

.chat-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 800px;
    background: #e1e6ef;
}

.chat-details .heading {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #334155;
    margin: 3px 0px;
}

.chat-details .call {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
}

.chat-details .subheading {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #858993;
}

.chat-details .chart-card {
    max-width: 360px;
    width: 100%;
    height: fit-content;
    padding: 10px;
    gap: 4px;
    background: white;
    /* border-radius: 20px; */
}
.sender-item {
    border-radius: 8px 8px 0px 8px !important;
}
.receiver-item {
    border-radius: 8px 8px 8px 0px !important;
    background: #99a3b8 !important;
    color: white !important;
}

.chat-details-media {
    max-width: 360px;
    width: 100%;
}

.chat-details .chatText {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #99a3b8;
}

.chat-details .time {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    color: #99a3b8;
}

.chat-details button {
    width: 44px;
    height: 48px;
    border-radius: 10px;
    background: #7f6df5;
    border: 0;
}

.chat-details button :hover {
    opacity: 50%;
}

.chat-details .messageBox {
    display: flex;
    flex-direction: row;
    margin: 20px 40px;
    gap: 10px;
    position: relative;
}

.chat-details .msgsCont {
    display: flex;
    flex-direction: column;
    padding: 40px;
    /* gap: 50px; */
    /* height: 500px; */
    height: 585px !important;
    overflow: scroll;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.chat-details .chat-input {
    width: 100%;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #99a3b8;
}

.chat-input::placeholder {
    color: #99a3b8;
}

.chat-input:focus {
    outline: none;
}

.selected-file {
    padding-left: 10px;
    padding-right: 10px;
    background: #f3f8fe;
    position: absolute;
    top: -30px;
    right: 50px;
    border-radius: 4px;
}

.chat-media {
    width: 200px;
    height: 150px;
    border-radius: 12px;
}

.chat-sticky-date {
    top: -1px;
    background: #e1e6ef;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    z-index: 99;
}

@media (max-width: 991.99px) {
    .chat-details .msgsCont {
        padding: 0px 20px;
    }
    .chat-details .messageBox {
        margin: 20px;
    }
}
.arrow-rotate {
    transform: rotate(180deg) !important;
}

.receiver-item .chatText,
.time {
    color: white !important;
}
.chat-initiate-btn {
    background: #0059ab1a !important;
    color: #0059ab !important;
    font-weight: 400 !important;
}
.chat-initiate-btn h6 {
    font-weight: 400 !important;
    font-size: 12px !important;
}
