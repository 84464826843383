.reviews-section {
    border-radius: 20px;
    max-height: 591px;
    border: 1px solid var(--c-e1e6ef);
    padding: 20px;
}

.reviews-container {
    max-height: 543px;
    overflow-y: auto;
}

.reviews-container::-webkit-scrollbar {
    width: 5px;
}

.reviews-container::-webkit-scrollbar-thumb {
    background: var(--c-334155);
    border-radius: 12px;
}

.reviews-container::-webkit-scrollbar-track {
    background: rgba(203, 203, 203, 0.703);
    border-radius: 12px;
    margin: 10px 20px;
}

.review-avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}
.review-hr {
    border-top: 2px solid var(--c-e1e6ef);
}
