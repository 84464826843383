.account-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 16px;
  width: 502px;
  height: 76px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.register-container {
  margin-top: 100px !important;
}
.create-account {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 32px;
}
.main-register-container {
  max-width: 502px !important;
}
.sign-in-professional {
  color: #fed7aa;
}

.auth-banner {
  height: 100vh;
  background-image: url("../../assets/images/auth/banner-bg.png");
  background-repeat: no-repeat;
  background-size: 100% !important;
  border-radius: 20px;
  padding: 40px !important;
}

.auth-banner-bottom {
  /* background: #ffffff1a; */
  border-radius: 30px;
  padding: 50px;
  background-image: url("../../assets/images/auth/bottom-bg.png");
  background-repeat: no-repeat;
  background-size: 100% !important;
}
.auth-banner-bottom-title {
  font-size: 41px;
}

.auth-banner-right {
  height: 98vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1023.99px) {
  .auth-banner {
    display: none;
  }
}
@media (max-width: 1200px) {
  .signup-banner {
    display: none;
  }
  .account-container {
    width: 490px;
    padding: 10px;
  }
}
@media (max-width: 500px) {
  .account-container {
    width: 100%;
    padding: 10px;
  }
}
