.card-step {
    border-radius: 24px;
    border: 1px solid #e1e6ef;
    background: #fff;
    padding: 60px;
    box-sizing: content-box;
}

.btn.btn-back,
.btn.btn-back:hover {
    width: -webkit-fill-available !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: var(--c-334155) !important;
    background: var(--c-331a) !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: normal !important;
    border-radius: 140px !important;
    border: 0 !important;
    padding: 12px 6.125rem !important;
}

.btn-next,
.btn-next:hover {
    width: -webkit-fill-available !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: var(--bs-white) !important;
    background: var(--c-334155) !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: normal !important;
    border-radius: 140px !important;
    border: 0 !important;
    padding: 12px 6.125rem !important;
}

.num-border {
    height: 30px;
    width: 30px;
    background: var(--c-331a);
    border: 1px solid var(--c-331a);
    border-radius: 50%;
    color: var(--c-334155);
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-after.active .num-border {
    border: 1px solid var(--c-334155);
    background: transparent;
}

.hl {
    width: 100%;
    border: 1px solid var(--c-e8e8e8);
}

.l-12 {
    left: 12px;
}

.d-4-none:nth-last-of-type(2) {
    display: block;
}

@media only screen and (max-width: 425px) {
    .hl {
        width: 5rem;
    }

    .d-4-none:nth-last-of-type(2) {
        display: none;
    }

    .l-12 {
        left: 33px;
    }

    .card-step {
        padding: 40px 0;
        border: 0;
    }

    .checked-text {
        width: 150px;
    }
}

.hl-col:nth-last-of-type(1) {
    display: none !important;
}

.step-check {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    background: var(--c-334155);
    border-radius: 50%;
}

.border-b-1-e8 {
    border-bottom: 2px solid var(--c-e8e8e8);
}

/* Step 2 CheckBox Start */

/* The container */
.checkbox-container {
    height: 130px;
    width: 100%;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-service-col {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 8px;
    padding-right: 8px;
}

@media (min-width: 380px) {
    .custom-service-col {
        width: 50%;
    }
}

@media (min-width: 768px) {
    .custom-service-col {
        width: 33.3333%;
    }
}

/* Create a custom checkbox */
.bg-checkbox {
    display: flex;
    width: 150px;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 130px;
    border-radius: 16px;
    border: 1px solid #e1e6ef;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover .step-input ~ .bg-checkbox .checkmark {
    border: 1px solid var(--c-334155);
}

.checkbox-container .step-input:checked ~ .bg-checkbox .checkmark {
    border: 1px solid var(--c-334155);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after,
.bg-checkbox:before {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container .step-input:checked ~ .bg-checkbox:before,
.checkbox-container .step-input:checked ~ .bg-checkbox .checkmark:after {
    display: block;
}

.checkbox-container .bg-checkbox:before {
    width: 20px;
    height: 20px;
    background: var(--c-334155);
    border-radius: 50%;
    top: -9px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
}

/* Style the checkmark/indicator */
.checkbox-container .bg-checkbox .checkmark:after {
    left: 50%;
    top: -3px;
    width: 3px;
    height: 6px;
    border: solid var(--bs-white);
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg) translateX(-50%);
    -ms-transform: rotate(45deg) translateX(-50%);
    transform: rotate(45deg) translateX(-50%);
    z-index: 99;
}

.step-input:checked ~ .checked-color svg path {
    fill: var(--c-334155);
}

.checked-text {
    color: var(--c-667085);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5rem;
    display: block;
    width: 150px;
    text-align: center;
}

.step-input:checked ~ .checked-color .checked-text {
    color: var(--c-0f151d);
}

/* Step 2 CheckBox End */

.w-200 {
    width: 200px;
}

.h-84 {
    height: 5.25rem;
}

.max-w-512 {
    max-width: 32rem;
}

.flex-wrap-nowrap {
    flex-wrap: nowrap !important;
}

.instagram-modal {
    display: block !important;
    position: fixed !important;
    background: #00000099 !important;
    z-index: 9999 !important;
    height: 100vh !important;
    width: 100% !important;
}

.instagram-modal-dialog {
    margin: 0 auto;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 1.75rem * 2);
}

.instagram-modal-content {
    background: #ffffff;
    width: 500px;
    padding: 25px;
    border-radius: 5px;
    height: 90%;
    overflow-y: auto;
}

.br-5 {
    border-radius: 5px;
}

.top-10 {
    top: 10px;
}

.right-10 {
    right: 10px;
}

.max-h-500 {
    max-height: 500px;
}

.left-0 {
    left: 0;
}

.dash-2-e1e6ef {
    border: 2px dashed #e1e6ef;
}

.insta-container {
    height: 90vh;
    overflow-y: auto;
}


@media only screen and (max-width: 425px) {
    .hide_Step_xs {
        display: none;
    }
}