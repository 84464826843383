.service-card {
    border-radius: 12px !important;
    position: relative;
    width: 350px;
    height: 100%;
}
.service-card-top-img {
    width: 100%;
    height: 192px;
    background: whitesmoke;
    overflow: hidden;
    border-radius: 12px 12px 0px 0px !important;
}

.service-card-top-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.service-card-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 15px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e1e6ef;
    padding: 10px;
}

.service-details-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;

    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b2b2b2;
}


.service-main-image {
    height: 424px;
    width: 100%;
    border-radius: 14px;
    object-fit: cover;
    object-position: center;
}
.service-sub-image {
    height: 202px;
    width: 100%;
    border-radius: 14px;
    object-fit: cover;
    position: relative;
}
.service-image-section img {
    border-radius: 14px;
    object-fit: cover;
}
.service-sub-image .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
}

.modal-service-image {
    height: 240px;
    border-radius: 14px;
    object-fit: cover;
    position: relative;
}
.nearby-business-address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.nearby-business-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.services .nav-tabs .nav-item .nav-link {
    border-bottom: 1px solid #e1e6ef;
    color: var(--c-667085) !important;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    padding-bottom: 20px !important;
    padding-top: 20px !important;
}

.services .nav-tabs .nav-item .nav-link.active {
    color: var(--c-0f151d) !important;
    border: none !important;
    font-weight: 500 !important;
    border-bottom: 1px solid var(--c-0f151d) !important;
}
.services .nav-tabs .nav-link:focus-visible {
    box-shadow: none !important;
}

.services-header {
    flex-wrap: nowrap;
    overflow-x: auto !important;
}

.services-header-item {
    border-radius: 30px;
    padding: 10px 16px 10px 10px;
    border: 1px solid var(--c-e1e6ef);
    font-size: 16px;
    flex-wrap: nowrap;
    color: var(--c-667085);
}
.services-header-item-active {
    color: var(--c-0f151d) !important;
    font-weight: 500 !important;
    border: 1px solid var(--c-0f151d) !important;
}
.service-book-now-card {
    border: 1px solid #e1e6ef;
    border-radius: 20px;
    padding: 24px;
}
.service-dot {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: var(--c-667085);
}
.book-now-transparent {
    padding: 10px 18px;
    background: none !important;
    border: 1px solid var(--c-334155);
    border-radius: 40px;
}
.near-by-you {
    position: relative;
    display: flex;
    white-space: nowrap;
    overflow-x: hidden;
    height: 100%;
    cursor: all-scroll;
    width: 1450px;
}
.near-by-you-inner {
    width: 100%;
    overflow: auto;
}

@media (max-width: 1024px) {
    .near-by-you {
        width: 1150px;
    }
    }

@media (max-width: 600px) {
    .near-by-you {
        width: 750px;
    }
    }

@media (max-width: 380px) {
    .near-by-you {
        width: 100%;
    }
    .near-by-you-inner {
        width: 100%;
        overflow: hidden;
    }
    .service-card {
        border-radius: 12px !important;
        position: relative;
        width: 100%;
        height: 100%;
    }
    }


.carousel-left-btn {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.carousel-right-btn {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
}

.h-36 {
    height: 36px;
}

.single-service-card {
    border: 1px solid #e1e6ef;
    border-radius: 20px;
    padding: 24px;
}
.continue-card {
    border: 1px solid #e1e6ef;
    border-radius: 20px;
    padding: 24px;
}

.custom-checkbox {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b0b2b5;
}

.custom-checkbox-active {
    background: #334155;
}
.select-time {
    width: 96px;
    /* height: 64px; */
    border-radius: 10px;
    border: 1px solid #e1e6ef;
}
.appointment-time {
    width: 292px;
}

.appointment-time .heading {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.appointment-time .professional {
    font-family: Poppins;
    color: var(--c-0f151d);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.appointment-time .assigned {
    font-family: Poppins;
    color: var(--c-667085);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}
.appointment-time .link {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: right;
    color: var(--c-334155);
}

.custom-check {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b0b2b5;
}
.date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 15px;
    flex: none;
    order: 1;
    flex-grow: 0;
    max-width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
}

.date-container .date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 107px;
    height: 110px;
    padding: 0;
    border-radius: 20px;
    gap: 5px;
    border: 1px solid #e1e6ef;
}

.date-container .date .day {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selected-date {
    background-color: #334155;
    color: white;
}
.modal-width {
    width: 640px;
    height: 100%;
}

/* Search Bar */
.searchBar {
    padding: 8px, 8px, 8px, 20px;
    border-radius: 360px;
    border: 1px solid #e1e6ef;
    gap: 6px;
}

.search-btn {
    font-size: 1rem !important;
    font-weight: 400 !important;
    border-radius: 22.5rem !important;
    background: var(--c-334155) !important;
    color: var(--bs-white) !important;
    padding: 0.875rem 2.9375rem !important;
}

@media (max-width: 767.99px) {
    .service-main-image {
        height: 200px !important;
    }
    .service-sub-image {
        height: 106px !important;
        width: 48% !important;
    }
    .overlay-text {
        font-size: 16px !important;
    }
    .date-container {
        gap: 10px;
    }
    .date-container .date {
        width: 70px;
        height: 72px;
        border-radius: 8.17px;
        gap: 3.27px;
        border: 0.66px solid #e1e6ef;
        font-size: 11px;
    }
    .search-btn {
        padding: 0.5rem 2rem !important;
    }
}

@media (max-width: 500px) {
    .select-time {
        width: 96px;
        /* height: 64px; */
        gap: 4px;
        border-radius: 10px;
        border: 1px solid #e1e6ef;
    }
    .date-container {
        gap: 5px;
    }

    .date-container .date {
        width: 43.75px;
        height: 44.92px;
        border-radius: 8.17px;
        gap: 3.27px;
        border: 0.41px solid #e1e6ef;
        font-size: 8px;
    }
    .searchBar {
        border: none;
    }
    .search-btn {
        font-size: 1rem !important;
        font-weight: 400 !important;
        border-radius: 22.5rem !important;
        background: var(--c-334155) !important;
        color: var(--bs-white) !important;
        padding: 0.75rem 2rem !important;
    }
    .search-icon {
        box-shadow: 1px 1px 8px 0px #0000001a;
        padding: 8px 20px !important;
    }
}

.search-services-dropdown {
    max-width: 300px;
    max-height: 320px;
    height: auto;
    overflow-y: auto;
}

.active_service {
    background-color: #c5c5c5 !important;
}
.active_service:hover {
    background-color: #c5c5c5 !important;
}

.slick-track {
    margin-left: 0 !important;
}