
.container-content-img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
    }
    
    
    

.border-card-pill{
    /* width: 120px ; */
    /* height: 35px; */
    border: 1px solid #E1E6EF;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.link-color{
    font-family: Poppins;
    color: #3A475C;
    font-size: 14px;
    line-height: 20px;
}
.dot {
    width: 4px;
    height: 4px;
    background-color: #667085; 
    border-radius: 50%; 
    display: inline-block; 
    padding-left: 1px;
    margin-bottom: 2px;
  }
  .maincontainer{
    display: flex;
    flex-direction: row;
    gap: 50px;
  }
  .search_address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }
 

@media screen and (max-width: 1200px) {
    .maincontainer {
        display: flex;
     flex-direction: column;
    }
    .container-content-img{
      width: 100%;
    }
  }

