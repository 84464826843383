::-webkit-scrollbar {
    width: 0;
    height: 0;
}

@media only screen and (max-width: 425px) {
    .wh-sm-18 .svg-18 {
        height: 16px !important;
        width: 16px !important;
    }

    .wh-sm-12 .svg-40-sm-12 {
        height: 12px !important;
        width: 12px !important;
    }

    .pb-22 {
        padding-bottom: 36px !important;
    }

    .f-18-sm-11px {
        font-size: 11px !important;
    }

    .f-20-sm-12px,
    .f-28-sm-12px {
        font-size: 12px !important;
    }

    .pl-0px {
        padding-left: 0px !important;
    }

    .pr-0px {
        padding-right: 0px !important;
    }

    .p-30-sm-8 {
        padding: 8px !important;
    }

    .p-40-sm-12 {
        padding: 12px !important;
    }

    .pb-20-sm-0 {
        padding-bottom: 0px !important;
    }

    .pb-20-sm-6 {
        padding-bottom: 6px !important;
    }

    .mb-44-sm-13 {
        margin-bottom: 13px !important;
    }

    .br-20-sm-6 {
        border-radius: 6px !important;
    }

    .br-30-sm-9 {
        border-radius: 9px !important;
    }

    .small-card {
        width: 19.4375rem !important;
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .small-card {
        width: 311px !important;
    }
}

.svg-40-sm-12 {
    height: 40px;
    width: 40px;
}

.svg-18 {
    height: 24px;
    width: 24px;
}

.f-18-sm-11px {
    font-size: 1.125rem;
}

.f-20-sm-12px {
    font-size: 20px;
}

.f-28-sm-12px {
    font-size: 28px;
}

.p-30-sm-8 {
    padding: 30px;
}

.pb-20-sm-0,
.pb-20-sm-6 {
    padding-bottom: 20px;
}

.mb-44-sm-13 {
    margin-bottom: 44px;
}

.br-20-sm-6 {
    border-radius: 1.25rem;
}

.br-30-sm-9 {
    border-radius: 1.875rem !important;
}

.nav-link {
    color: var(--bs-white) !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: var(--bs-white) !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.header-bg {
    background: url('../../assets/images/header-bg.png') no-repeat center;
    background-size: cover;
}

.footer-bg {
    background: url('../../assets/images/footer-bg.png') no-repeat center;
    background-size: cover;
}

.c-gold {
    color: var(--gold) !important;
}

.border-bottom-2-gold {
    border-bottom: 2px solid var(--gold);
}

.c-0f151d {
    color: var(--c-0f151d);
}

.btn-search {
    font-family: 'Poppins', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    border-radius: 22.5rem !important;
    background: var(--c-334155) !important;
    color: var(--bs-white) !important;
    padding: 0.875rem 2.9375rem !important;
}

.vl {
    border-right: 1px solid var(--c-d7e2f2);
}

.bg-hover-f3f8fe {
    background: var(--bs-white) !important;
}

.bg-hover-f3f8fe:hover {
    background: var(--c-f3f8fe) !important;
}

.br-top-bottom-left-50 {
    border-radius: 50px 0px 0px 50px;
}

.bg-ff {
    background: var(--bs-white);
}

.box-shadow-banner {
    box-shadow: 20px 20px 120px 15px rgba(0, 0, 0, 0.05);
}

.c-99a3b8 {
    color: var(--c-99a3b8);
}

.border-1-ff66 {
    border: 1px solid var(--c-ffffff66);
}

.border-1-fff33 {
    border-bottom: 1px solid var(--c-fff33);
}

.c-334155 {
    color: var(--c-334155) !important;
}

.c-667085 {
    color: var(--c-667085);
}

.card {
    border-radius: 12px !important;
    border: 1px solid var(--c-e1e6ef) !important;
    background: var(--bs-white) !important;
}

.bg-gold {
    background: var(--gold);
}

.br-15 {
    border-radius: 0.9375rem !important;
}

.br-16 {
    border-radius: 1rem !important;
}

.br-20 {
    border-radius: 1.25rem !important;
}

.border-1-001a {
    border: 1px solid #0000001a;
}

.bg-experience {
    background: url('../../assets/images/bg-experience.png') no-repeat;
    background-size: cover;
    border-radius: 30px;
}

.bg-123 {
    background: url('../../assets/images/123.png') no-repeat;
    background-size: cover;
}

.bg-456 {
    background: url('../../assets/images/456.png') no-repeat;
    background-size: cover;
}

.bg-789 {
    background: url('../../assets/images/789.png') no-repeat;
    background-size: cover;
}

.bg-gold-trans {
    background: var(--gold-trans);
}

.small-card {
    width: 20.25rem;
    min-height: 13.125rem;
    border-radius: 30px;
    background: var(--bs-white);
    text-align: center;
}

.small-card-p-1 {
    padding: 1.75rem 2.4375rem 1.75rem 2.5625rem;
}

.small-card-p-2 {
    padding: 29px 23px 9px 23px;
}

.small-card-p-3 {
    padding: 28px 23px 28px 24px;
}

.small-card-p-4 {
    padding: 24px 24px 34px 24px;
}

.bg-navy-blue {
    background: var(--c-334155);
}

.btn-left-arrow,
.btn-right-arrow {
    border: 0;
    outline: 0;
    background: transparent;
}

.slider-card {
    width: 26.25rem;
    height: 20.125rem;
    position: relative;
    border: 2px solid #e8e8e8;
    border-radius: 50px;
}
.carousel-item-quote {
    position: absolute;
    top: 40px;
    right: 40px;
}

.br-50 {
    border-radius: 3.125rem;
}

.br-top-bottom-left-md-50 {
    border-radius: 50px !important;
}

.border-radius-md-0 {
    border-radius: 50px !important;
}

.bg-half {
    background: url('../../assets/images/bg-half.png') no-repeat;
    background-size: auto;
}

.btn-find-out {
    border-radius: 90px !important;
    background: #334155 !important;
    color: #fff !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: normal !important;
    padding: 16px 39px !important;
}

.smallest-card {
    border-radius: 30px;
    border: 2px solid #e8e8e8;
    background: #fff;
    padding: 30px;
}

.c-344255 {
    color: var(--c-344255);
}

.br-30 {
    border-radius: 30px !important;
}

.dropdown-menu.dropdown-card {
    padding: 1.75rem;
    max-width: 376px;
    width: 100%;
    overflow: hidden;
    box-sizing: content-box;
}

@media screen and (max-width: 471px) {
    .dropdown-menu.dropdown-card {
        padding: 0.75rem;
        max-width: 300px;
        width: 100%;
        overflow: hidden;
        box-sizing: content-box;
    }
    .selector {
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        gap: 8px;
    }
}

.selector {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selector-item {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selector-item_radio {
    appearance: none;
    display: none;
}

.selector-item_label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--c-334155) !important;
    background: var(--bs-white) !important;
    border: 1px solid #00000033 !important;
    border-radius: 360px;
    height: 32px;
    /* width: 90px; */
    padding: 4px 12px;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 20px;
    transition-duration: 0.5s;
    cursor: pointer;
}

.selector-item_radio:checked + .selector-item_label {
    background-color: var(--c-334155) !important;
    color: var(--bs-white) !important;
}

.bg-from-to {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: var(--bs-white);
    padding: 10px 16px 14px 16px;
}

.from {
    color: rgba(0, 0, 0, 0.4);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.text-white-80 {
    color: #ffffffcc;
}

.testimonial-left-section {
    max-width: 320px;
    min-width: 320px;
    width: 100%;
    padding-right: 40px;
}
.testimonial-right-section {
    width: 100%;
    min-width: 1300px;
    overflow-x: auto;
}

@media screen and (max-width: 991px) {
    .testimonial-left-section {
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 0px;
    }
    .slider-card {
        min-width: 300px !important;
        width: 100%;
        position: relative;
        border: 2px solid #e8e8e8;
        border-radius: 50px;
    }
    .testimonial-right-section {
        width: 100%;
        min-width: 1000px;
        overflow-x: auto;
    }
}

@media screen and (max-width: 680px) {
    .testimonial-right-section {
        width: 100%;
        min-width: 800px;
        overflow-x: auto;
    }
}
@media screen and (max-width: 420px) {
    .testimonial-right-section {
        width: 100%;
        min-width: 200px;
        overflow-x: hidden;
    }
    .slider-card {
        min-width: 200px !important;
        width: 100%;
        position: relative;
        border: 2px solid #e8e8e8;
        border-radius: 50px;
    }
}

.beauty-services-card {
    background-color: #f1f2f380;
    padding: 40px;
    border-radius: 30px;
    position: relative;
    height: 100%;
}

.beauty-services-card .services-image-container {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    border: 1px solid #0000001a;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.beauty-services-card .fixed-icon {
    position: absolute;
    overflow: hidden;
    width: 80px;
    bottom: 40px;
    right: 20px;
}
.beauty-services-card .fixed-icon img {
    width: 100%;
}

.beauty-services-card .number-count {
    font-size: 28px;
    color: #344255;
}

.beauty-services-card .desc {
    font-size: 18px;
    color: #344255;
}

@media screen and (max-width: 991px) {
    .beauty-services-card {
        padding: 20px;
        border-radius: 20px;
    }

    .beauty-services-card .services-image-container {
        width: 80px;
        height: 80px;
        border-radius: 12px;
        margin-bottom: 32px;
    }
    .beauty-services-card .fixed-icon {
        width: 60px;
        bottom: 20px;
        right: 20px;
    }
    .beauty-services-card .number-count {
        font-size: 24px;
    }

    .beauty-services-card .desc {
        font-size: 14px;
    }
}
@media screen and (max-width: 767px) {
    .beauty-services-card {
        padding: 8px;
        border-radius: 8px;
    }
    .beauty-services-card .services-image-container {
        width: 60px;
        height: 60px;
        border-radius: 12px;
        margin-bottom: 16px;
    }
    .beauty-services-card .fixed-icon {
        width: 30px;
        bottom: 8px;
        right: 8px;
    }
    .beauty-services-card .number-count {
        font-size: 16px;
    }

    .beauty-services-card .desc {
        font-size: 12px;
    }
}
@media screen and (max-width: 380px) {
    .beauty-services-card .number-count {
        font-size: 14px;
    }

    .beauty-services-card .desc {
        font-size: 10px;
    }
}
