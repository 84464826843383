.appointment-container {
    border-width: 0 1px 0px 0;
    border-style: solid;
    border-color: #e1e6ef;
}
.appointment-container .heading {
    font-family: 'Poly';
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--c-344255);
}
.appointment-container .description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: var(--c-667085);
}

.appointment-card-list {
    max-height: 480px;
    height: 100%;
    overflow-y: auto;
}

.appointment-card {
    display: flex;
    flex-direction: row;
    border-width: 0 0px 1px 0;
    border-style: solid;
    border-color: #e1e6ef;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.appointment-card.active {
    background-color: #E1E6EF;
}
.appointment-card .heading {
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.appointment-card .price {
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.appointment-card .date {
    font-family: Poppins;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--c-667085);
}

.profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    width: 80px;
    height: 80px;
    border-radius: 12px;
}
.Appointment-detail {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 10px;
    background: #ffffff;
    border: 1px solid #e1e6ef;
    border-radius: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.image-container {
    max-width: 100%;
    height: 292px;
    background: whitesmoke;
    overflow: hidden;
    border-radius: 20px;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
}

.manage-appointment-card {
    border: 1px solid var(--c-e1e6ef);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-radius: 20px;
    padding: 30px;
    width: 100%;
}
.manage-appointment-card .heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--c-0f151d);
}
.manage-appointment-card .description {
    font-family: Poppins;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--c-667085);
}

.manage-appointment-card-arrow {
    border: 1px solid var(--c-e1e6ef);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 5px;
}

.overview .heading {
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
}
.info-heading {
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.cancellation-heading {
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.review-btn {
    display: flex;
    gap: 4px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    background: #e26b001a;
    border-radius: 16px;
    height: 36px;
    color: #e26b00;
    margin: 0;
}
.confirmation-badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    background: rgba(36, 150, 7, 0.1);
    border-radius: 16px;
    height: 36px;
    margin: 0;
}
.confirmation-badge > span {
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
}

@media (max-width: 991.99px) {
    .appointment-card {
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: #e1e6ef;
    }
    .appointment-container {
        border: none;
    }
}

@media screen and (max-width: 500px) {
    .appointment-container {
        border: none;
    }
    /* .appointment-card {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #e1e6ef;
  } */
}


.customer.pending {
    color: orange;
}

.customer.accepted {
    color: blue;
}
.customer.completed {
    color: #249607;
}