.write-review-heading {
    font-size: 36px;
    color: #334155;
    text-align: center;
    font-weight: 500;
}
.review-heading-desc {
    color: #667085;
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
}

.write-review-container {
    margin: 0 auto;
    max-width: 605px;
    width: 100%;
    border: 1px solid #e1e6ef;
    padding: 60px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.give-rating-container {
    padding: 24px;
    border-radius: 20px;
    background-color: #eaf1ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.give-rating-container h2 {
    font-size: 18px;
    font-weight: 500;
}

.write-review-container label {
    color: #667085;
    margin-bottom: 8px;
}

.write-review-container textarea {
    width: 100%;
    border: 1px solid #e1e6ef;
    padding: 16px;
    border-radius: 8px;
    resize: none;
    outline: none;
}

.write-review-container .btn {
    border-radius: 140px;
    padding: 16px;
    width: 100%;
}
.write-review-container .back-btn {
    background-color: #3341551a;
}
.write-review-container .back-btn:hover {
    background-color: #85888d1a;
}
.write-review-container .send-review-btn {
    background-color: #334155;
    color: white;
}
.write-review-container .send-review-btn:hover {
    background-color: #50617a;
    color: white;
}

@media screen and (max-width: 991px) {
    .write-review-container {
        padding: 40px;
    }
}
@media screen and (max-width: 767px) {
    .write-review-container {
        padding: 20px;
    }
}
