.booking-map-section {
  margin-top: 55px;
  border-radius: 20px;
  border: 1px solid var(--c-e1e6ef);
}
.fw-600 {
  font-weight: 600 !important;
}

.booking-btn {
  background: var(--c-334155);
  border-radius: 40px;

  padding: 16px 40px;
  border: none !important;
}

.booking-btn:disabled {
  background-color: #c2c2c2;
}

@media (max-width: 767.99px) {
  .booking-map-section {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
