:root {
    --blue: #0aa9e1;
    --green: #0ae1a0;
    --black: #000000;
    --gold: #fed7aa;
    --gold-trans: #fdf9f4;
    --red: #eb5757;
    --transparant: transparant;
    --c-0f151d: #0f151d;
    --c-334155: #334155;
    --c-d7e2f2: #d7e2f2;
    --c-f3f8fe: #f3f8fe;
    --c-99a3b8: #99a3b8;
    --c-ffffff66: #ffffff66;
    --c-fff33: #ffffff33;
    --c-667085: #667085;
    --c-e1e6ef: #e1e6ef;
    --c-344255: #344255;
    --c-101828: #101828;
    --c-331a: #3341551a;
    --c-e8e8e8: #e8e8e8;
    --c-f93232: #f93232;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poly&display=swap');

.poppins {
    font-family: 'Poppins', sans-serif;
}

.poly {
    font-family: 'Poly', serif;
}

.landing-page-body {
    overflow-x: hidden !important;
}

.cp {
    cursor: pointer;
}

@media only screen and (min-width: 320px) {
    html {
        font-size: 12px;
    }

    .f-100 {
        font-size: 3.25rem !important;
    }

    .btn-create-acc,
    .btn-create-acc:hover {
        padding: 11px 145px !important;
    }

    .f-mobile-16 {
        font-size: 16px !important;
    }
}

@media only screen and (min-width: 768px) {
    .px-md-16 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .pl-md-120 {
        padding-left: 7.5rem !important;
    }

    .pr-md-40 {
        padding-right: 2.5rem !important;
    }

    .pl-125 {
        padding-left: 7.8125rem !important;
    }

    .max-w-md-255 {
        max-width: 15.9375rem !important;
    }

    .max-w-md-235 {
        max-width: 14.6875rem !important;
    }

    .max-w-md-470 {
        max-width: 29.375rem;
    }

    /*.max-w-md-605 {*/
    /*    max-width: 37.8125rem !important;*/
    /*}*/

    .max-w-md-976 {
        max-width: 61rem !important;
    }
}

@media only screen and (min-width: 960px) {
    html {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1240px) {
    html {
        font-size: 16px;
    }

    .w-md-934 {
        width: 58.375rem !important;
    }

    .h-md-572 {
        height: 35.75rem !important;
    }

    .gap-md-261 {
        gap: 16.3125rem;
    }

    .bg-md-ff {
        background: var(--bs-white);
    }

    .br-top-bottom-left-md-50 {
        border-radius: 50px 0px 0px 50px !important;
    }

    .border-radius-md-0 {
        border-radius: 0 !important;
    }

    /* Padding MD Start*/

    .px-md-9 {
        padding-right: 0.5625rem !important;
        padding-left: 0.5625rem !important;
    }

    .px-md-120 {
        padding-left: 7.5rem !important;
        padding-right: 7.5rem !important;
    }

    .pt-md-11 {
        padding-top: 0.6875rem !important;
    }

    .pt-md-70 {
        padding-top: 4.375rem !important;
    }

    .pt-md-120 {
        padding-top: 7.5rem !important;
    }

    .pl-md-133 {
        padding-left: 8.3125rem !important;
    }

    .pr-md-120 {
        padding-right: 7.5rem !important;
    }

    .pr-md-7-5 {
        padding-right: 0.4688rem !important;
    }

    .pl-md-7-5 {
        padding-left: 0.4688rem !important;
    }

    .pb-md-200 {
        padding-bottom: 12.5rem !important;
    }

    .py-md-29 {
        padding-top: 1.8125rem !important;
        padding-bottom: 1.8125rem !important;
    }

    .pr-md-32 {
        padding-right: 2rem !important;
    }

    .pr-md-34 {
        padding-right: 2.125rem !important;
    }

    .pr-md-61 {
        padding-right: 3.8125rem !important;
    }

    .pl-md-35 {
        padding-left: 2.1875rem !important;
    }

    .py-md-11 {
        padding-top: 0.6875rem !important;
        padding-bottom: 0.6875rem !important;
    }

    .px-md-20 {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
    }

    .py-md-24 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .pr-md-57 {
        padding-right: 3.5625rem !important;
    }

    .pr-md-77 {
        padding-right: 4.8125rem !important;
    }

    .pt-md-12 {
        padding-top: 0.75rem !important;
    }

    .pb-md-110 {
        padding-bottom: 6.875rem !important;
    }

    .px-md-45 {
        padding-left: 2.8125rem !important;
        padding-right: 2.8125rem !important;
    }

    .pb-md-30 {
        padding-bottom: 1.875rem !important;
    }

    .pt-md-10 {
        padding-top: 0.625rem !important;
    }

    .pb-md-20 {
        padding-bottom: 1.25rem !important;
    }

    .py-md-80 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .pt-md-100 {
        padding-top: 6.25rem !important;
    }

    .py-md-100 {
        padding-top: 6.25rem !important;
        padding-bottom: 6.25rem !important;
    }

    .pb-md-100 {
        padding-bottom: 6.25rem !important;
    }

    .pb-md-40 {
        padding-bottom: 2.5rem !important;
    }

    .pb-md-60 {
        padding-bottom: 3.75rem !important;
    }

    .pl-md-30 {
        padding-left: 1.875rem !important;
    }

    .pl-md-44 {
        padding-left: 2.75rem !important;
    }

    .pb-md-43 {
        padding-bottom: 2.6875rem !important;
    }

    .pr-md-16 {
        padding-right: 1rem !important;
    }

    /* Padding MD End */

    /* Margin MD Start */

    .mr-md-40 {
        margin-right: 2.5rem !important;
    }

    .ml-md-8 {
        margin-left: 0.5rem !important;
    }

    .mb-md-24 {
        margin-bottom: 1.5rem !important;
    }

    .mb-md-50 {
        margin-bottom: 3.125rem !important;
    }

    .mt-md--46 {
        margin-top: -2.875rem !important;
    }

    .mb-md--20 {
        margin-bottom: -20rem;
    }

    .mb-md-82 {
        margin-bottom: 5.125rem;
    }

    /* Margin MD End */
}

@media only screen and (min-width: 1400px) {
    .pl-60px {
        padding-left: 3.75rem !important;
    }

    .px-lg-29 {
        padding-left: 1.8125rem !important;
        padding-right: 1.8125rem !important;
    }

    .w-732px {
        width: 732px !important;
    }

    .max-w-881px {
        max-width: 881px !important;
    }

    .max-w-1159px {
        max-width: 1159px !important;
    }

    .max-w-1385px {
        max-width: 1385px !important;
    }

    .max-w-1504px {
        max-width: 1504px !important;
    }

    .max-w-1684px {
        max-width: 1684px !important;
    }

    .max-w-859 {
        max-width: 53.6875rem !important;
    }

    .max-w-847 {
        max-width: 52.9375rem !important;
    }

    .max-w-836 {
        max-width: 52.25rem !important;
    }

    .max-w-974 {
        max-width: 60.875rem !important;
    }

    .max-w-1023 {
        max-width: 63.9375rem !important;
    }

    .max-w-305 {
        max-width: 19.0625rem !important;
    }

    .max-w-327 {
        max-width: 20.4375rem !important;
    }

    .max-w-350 {
        max-width: 21.875rem !important;
    }

    .min-w-473 {
        min-width: 29.5625rem !important;
    }

    .min-w-369 {
        min-width: 23.0625rem !important;
    }

    .min-max-w-455 {
        min-width: 28.4375rem !important;
        max-width: 28.4375rem !important;
    }
}

/* font-size Start */

.f-13 {
    font-size: 0.795rem;
}

.f-14 {
    font-size: 0.875rem;
}

.f-16 {
    font-size: 1rem;
}

.f-18 {
    font-size: 1.125rem;
}

.f-20 {
    font-size: 1.25rem;
}

.f-22 {
    font-size: 1.375rem;
}

.f-24 {
    font-size: 1.5rem;
}

.f-25 {
    font-size: 1.5625rem;
}

.f-30 {
    font-size: 1.875rem;
}

.f-36 {
    font-size: 2.25rem;
}

.f-36px {
    font-size: 36px;
}

.f-42 {
    font-size: 2.625rem;
}

.f-50 {
    font-size: 3.125rem;
}

.f-60 {
    font-size: 3.75rem;
}

.f-70 {
    font-size: 4.375rem;
}

.f-100 {
    font-size: 6.25rem;
}

/* font-size End */

.lh-0 {
    line-height: 0;
}

.lh-normal {
    line-height: normal;
}

.lh-16 {
    line-height: 1rem;
}

.lh-17 {
    line-height: 1.0625rem;
}

.lh-20 {
    line-height: 1.25rem;
}

.lh-23 {
    line-height: 1.4375rem;
}

.lh-24 {
    line-height: 1.5rem;
}

.lh-26 {
    line-height: 1.625rem;
}

.lh-32 {
    line-height: 2rem;
}

.lh-36 {
    line-height: 2.25rem;
}

.lh-40 {
    line-height: 2.5rem;
}

.lh-48 {
    line-height: 3rem;
}

.lh-120 {
    line-height: 7.5rem;
}

/* Padding Start */

.pb-6 {
    padding-bottom: 0.375rem;
}

.pr-6 {
    padding-right: 0.375rem;
}

.px-6 {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.pl-6 {
    padding-left: 0.375rem;
}

.pr-11 {
    padding-right: 0.6875rem;
}

.pr-13 {
    padding-right: 0.8125rem;
}

.pr-16 {
    padding-right: 1rem;
}

.pr-12 {
    padding-right: 0.75rem;
}

.py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.pb-9 {
    padding-bottom: 0.5625rem;
}

.py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.pr-8 {
    padding-right: 0.5rem;
}

.pl-20 {
    padding-left: 1.25rem;
}

.pr-57 {
    padding-right: 3.5625rem;
}

.pr-17 {
    padding-right: 1.0625rem;
}

.pr-20 {
    padding-right: 1.25rem;
}

.px-20 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.pr-77 {
    padding-right: 4.8125rem;
}

.pb-12 {
    padding-bottom: 0.75rem !important;
}

.pb-8 {
    padding-bottom: 0.5rem !important;
}

.pt-32 {
    padding-top: 2rem;
}

.pt-40 {
    padding-top: 2.5rem;
}

.pb-40 {
    padding-bottom: 2.5rem;
}

.pb-41 {
    padding-bottom: 2.5625rem;
}

.pb-22 {
    padding-bottom: 1.375rem;
}

.px-16 {
    padding-right: 1rem;
    padding-left: 1rem;
}

.px-9 {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.pl-6 {
    padding-left: 0.375rem;
}

.p-15 {
    padding: 0.9375rem !important;
}

.p-20 {
    padding: 1.25rem !important;
}

.px-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
}

.py-4rem {
    padding-top: 0.25rem !important;
    padding-top: 0.25rem !important;
}

.pr-4 {
    padding-right: 0.25rem !important;
}

.p-30 {
    padding: 1.875rem !important;
}

.p-40 {
    padding: 2.5rem !important;
}

.pb-14 {
    padding-bottom: 0.875rem;
}

.pb-15 {
    padding-bottom: 0.875rem;
}

.pb-16 {
    padding-bottom: 1rem;
}

.pb-32 {
    padding-bottom: 2rem;
}

.pb-35 {
    padding-bottom: 2.1875rem;
}

.pb-11 {
    padding-bottom: 0.6875rem;
}

.pb-18 {
    padding-bottom: 1.125rem;
}

.pb-25 {
    padding-bottom: 1.5625rem;
}

.pb-20 {
    padding-bottom: 1.25rem;
}

.pb-30 {
    padding-bottom: 1.875rem !important;
}

.pb-65 {
    padding-bottom: 4.0625rem;
}

.pr-87 {
    padding-right: 87px;
}

.px-48 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.pt-4px {
    padding-top: 4px !important;
}

/* Padding End */

/* Margin Start */

.mb-44 {
    margin-bottom: 2.75rem;
}

.mx-20 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
}

.mb-24 {
    margin-bottom: 1.5rem;
}

.mb-16 {
    margin-bottom: 1rem;
}

.mb-8 {
    margin-bottom: 0.5rem;
}

.mb-14 {
    margin-bottom: 0.875rem;
}

.mb-20 {
    margin-bottom: 1.25rem;
}

.mb-82 {
    margin-bottom: 5.125rem;
}

/* Margin End */

/* height Start */

.h-2 {
    height: 2rem;
}

.h-8 {
    height: 0.5rem;
}

/* height End */

.box-sizing-content-box {
    box-sizing: content-box;
}

.text-danger {
    color: var(--red);
}

.input-filed,
.input-filed:focus {
    color: var(--c-0f151d);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-radius: 44px;
    border: 1px solid #e1e6ef;
    background: #fff;
    padding: 11px 26px;
    box-shadow: 0 0 0 0 !important;
}

.btn-country,
.btn-country:hover {
    color: var(--c-0f151d);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 11px 16px 11px 26px;
    border-radius: 44px;
    border: 1px solid #e1e6ef !important;
    background: #fff;
    width: -webkit-fill-available;
    text-align: start;
}

.c-101828 {
    color: var(--c-101828);
}

.c-344255 {
    color: var(--c-344255);
}

.c-bg {
    background: #334155;
}

.border-y-0 {
    border-top: 0;
    border-bottom: 0;
}

.border-l-0 {
    border-left: 0;
}

.border-r-e1e6ef {
    border-right: 2px solid #e1e6ef;
}

.form-check .form-check-input {
    margin-top: 0;
}

.form-check-input,
.form-check-input:focus {
    border-radius: 8px !important;
    width: 24px;
    height: 24px;
    box-shadow: 0 0 0 transparent !important;
}

.form-check-input:checked {
    background-color: #334155 !important;
    border-color: #334155 !important;
}

.box-shadow-0 {
    box-shadow: 0 0 0 !important;
}

.btn-create-acc,
.btn-create-acc:hover {
    border-radius: 40px;
    color: var(--bs-white);
    background: var(--c-334155);
    padding: 11px 166px;
    width: 100%;
    font-size: 16px;
}

.country-dropdown-menu {
    margin-top: 10px !important;
    width: 327px;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    box-shadow: -2px 2px 12px 2px rgba(21, 30, 42, 0.08);
    border-radius: 16px;
    background: #fff;
}

.country-dropdown-input {
    margin: 0 16px;
    width: -webkit-fill-available;
    padding: 4px 16px;
    border: 1px solid #ccc;
    border-radius: 44px;
}

.country-name-dropdown-menu {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    box-shadow: -2px 2px 12px 2px rgba(21, 30, 42, 0.08);
    border-radius: 16px;
    background: #fff;
}

.success-badge {
    background-color: #4caf50;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
}

.error-badge {
    background-color: #ff0000;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
}

.btn-country-num,
.btn-country-num:hover {
    color: var(--c-0f151d);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
    border: 0 !important;
    background: #fff;
    width: -webkit-fill-available;
    text-align: start;
}

.password-error-text {
    position: absolute;
    color: darkred;
    font-size: 12px;
    left: 27px;
}

.centered-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.l-0 {
    left: 0 !important;
}

.l-60 {
    left: 3.75rem !important;
}

.min-w-443 {
    min-width: 443px;
}

.check-box-custom {
    margin-top: 0;
    margin-right: 10px;
}

.wh-102-97 {
    width: 102px;
    height: 97px;
}

.h-38vh {
    height: 38vh;
}

.image-dimentions {
    width: 156.33px;
    height: 140px;
    /* border: 2px dashed #e1e6ef; */
    /* border-radius: 20px; */
}

.doted-border {
    border-width: 1px;
    border-style: dashed;
    border-color: #667085;
}

.image-dimentions:hover .cancel-pic {
    display: block;
}

.cancel-pic {
    display: none;
    position: absolute;
    top: 5%;
    right: 5%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.fs-9 {
    font-size: 9px;
}

.fs-12 {
    font-size: 12px;
}

.fw-600 {
    font-weight: 600;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-16 {
    font-size: 16px;
}

.fs-14 {
    font-size: 14px;
}

.fs-22 {
    font-size: 22px;
}

.w-auto {
    width: fit-content;
}

.h-auto {
    height: fit-content;
}

input:focus {
    outline: none;
    border: 1px solid #ccc;
}

input:active {
    border: 1px solid #ccc;
}

.customer_layout {
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;
}

.customer_layout_children {
    flex-grow: 1;
}

.filter-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 262px;
    position: absolute;
    top: 40px;
    right: 0px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e1e6ef;
    background: #ffffff;
    z-index: 2;
}

.filter-box input {
    width: 75px;
    padding: 5px;
    border: 2px solid #d2d2d2;
    border-radius: 8px;
    text-align: center;
}

.sort-btn {
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #d2d2d2;
    background: #fafafa;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
}

.filter-btn {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    height: 56px;
    width: 100%;
    border-radius: 40px;
    background: #334155;
    border: none;
    color: white;
}

.filter-btn:disabled {
    opacity: 50%;
}

.filter-trans button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 163px;
    /* height: 40px; */
    border: 1px solid #e1e6ef;
    padding: 4px 12px;
    border-radius: 40px;
    background: white;
    color: #667085;
    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: 400;
}

.filter-trans ul {
    padding: 10px;
    gap: 8px;
    border-radius: 8px;
    border: 1px;
}

.filter-trans li {
    display: flex;
    align-items: center;
    gap: 10px;
}

.filter-trans span {
    font-family: Poppins;
    font-size: 0.75rem;
    font-weight: 400;
}

.filter-trans ul :hover {
    background: #e1e6ef;
    cursor: pointer;
}

.filter-transtime {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 40px; */
    gap: 5px;
    border: 1px solid #e1e6ef;
    padding: 6px 12px;
    border-radius: 40px;
    background: white;
    color: #667085;
    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: 400;
}

.filter-transtime input {
    margin: 1px;
    border: none;
    font-size: 0.75rem;
}

.filter-transtime input:focus {
    border: transparent;
}

.filter-transtime .inputBox {
    border: 1px solid #e1e6ef;
    border-radius: 5px;
}

.user-dropdwon__links {
    padding-left: 0rem !important;
}

.custom-bg {
    background-color: #fff;
}

.custom-border {
    border: none;
}

.custom-container-border {
    border: 1px solid #e1e6ef;
}

@media screen and (max-width: 991px) {
    .custom-bg {
        background-color: transparent;
        /* Transparent white background color */
    }

    .custom-border {
        border: 1px solid #e1e6ef;
    }

    .custom-container-border {
        border: none;
    }
}

.white-space-no-wrap {
    white-space: nowrap;
}

.h__48 {
    height: 48px;
}

.cancellation-type-dropdown {
    position: absolute;
    top: calc(100% + 0px);
    /* Adjust as needed */
    right: 0;
    width: 272px;
    /* height: 272px; */
    min-height: 200px;
    border-radius: 16px;
    padding: 8px;
    box-shadow: -2px 2px 12px 2px #151e2a14;
    background: #ffffff;
    z-index: 2;
}

.cancellation-type-dropdown-item:hover {
    /* padding: 8px; */
    background: #d3d3d342;
    border-radius: 4px;
}

.cancellation-type-dropdown-item-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #0f151d;
}

.cancellation-type-dropdown-item-description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #667085;
}

.cancellation-border-bottom {
    border-bottom: 1px solid #e7e7e7;
}

.auth_bg {
    position: relative;
    min-height: 100vh;
    height: 100%;
    background-color: #000000;
}

.auth_bg:before {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(/public/signupBg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.5;
    /* Set your desired opacity here */
    /* z-index: -1; Ensure the pseudo-element is behind the content */
}

.auth_child {
    position: relative;
    z-index: 1;
    max-height: 98vh;
    height: auto;
    max-width: 630px;
    width: 100%;
    overflow: auto;
}

.input-unit {
    position: absolute;
    right: 19px !important;
    top: 10px;
    height: 100% !important;
    color: #667085;
}

/* .advance-booking {
    width: 230px !important;
} */

.clear-btn {
    padding: 7px 15px !important;
    background: #dc3027 !important;
}

.transport-filter-btn {
    padding: 7px 15px !important;
    border: 1px solid #e1e6ef;
    border-radius: 22.5rem !important;
    background: #334155;
}

.cancel-appointment-btn {
    padding: 10px 30px;
    gap: 10px;
    border-radius: 50px;
    color: #ffffff;
    background: #dc3027 !important;
    border: none !important;
}

/* Manage Appointment Modal */

@media (max-width: 767.99px) {
    .manage-appointment-modal {
        display: flex !important;
    }

    .manage-appointment-modal .right-modal-content {
        margin: auto !important;
        width: 90%;
    }
}

@media (max-width: 525px) {
    .image-dimentions {
        width: 49%;
        height: 140px;
    }
}


.reviewStars svg.star-svg {
    display: inline;
}

.bg-0059AB1A {
    background-color: #0059AB1A;
}

.confirmation-modal-header {
    background-color: #334155;
    display: flex;
    justify-content: center;
    padding: 16px;
}

.card-nav ul li.active a {
    color: #5f63f2 !important;
    font-weight: 500;
}

.ant-card {
    min-height: 100% !important;
}
/* 
.sidebar-scroll {
    overflow-y: auto;
}

.sidebar-scroll::-webkit-scrollbar {
    width: 5px;
}

.sidebar-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
}

.sidebar-scroll::-webkit-scrollbar-thumb:hover {
    background: grey;
} */


/* Example CSS for responsiveness */
.sd-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.performance-lineChart {
    padding-top: 20px;
}

@media (max-width: 575px) {
    .performance-lineChart {
        padding-top: 10px;
    }
}
.d-top-header {
    display: none;
}
@media (max-width: 1150px) {
    .d-top-header {
        display: flex;
    }
}
.chevron_btn_cus {
    border: 1px solid rgb(220, 217, 217);
    background-color: transparent;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

}